


import { useState, useEffect, useContext, createContext, useCallback, useRef } from "react";

import { ACTIVITY_SLIDE_KEY_PHRASE, DEBUG, DEFAULT_BACKGROUND_PROMPT_DELAY, DEFAULT_FAKE_STUDENT_FOLLOW_UP_DELAY, DEFAULT_INSTRUCTOR_FOLLOW_UP_DELAY, DEFAULT_SLIDE_MOVE_ON_DELAY, ERROR_OCCURRED_IMAGE, FAKE_STUDENT_ASK_QUESTION_DELAY, FAKE_STUDENT_NAME, INSTRUCTOR_NAME, LECTURE_SLIDE_PAUSE_BEFORE_WRAP_UP, MIN_SILENCE_BEFORE_NEXT_SLIDE, ONE_DAY_MILLIS, ONE_HOUR_MILLIS, ONE_MINUTE_MILLIS, ONE_SECOND_MILLIS, QUIZ_SLIDE_KEY_PHRASE, Q_AND_A_SLIDE_KEY_PHRASE, SERVER_ORIGIN, VERBOSE } from "../common/constants";
import { MessageType } from "../types/enums";

import { useSpeechRecognitionUpdate } from "./SpeechRecognitionContext";
import { useSpeechSynthesisUpdate } from "./SpeechSynthesisContext";
import { useSession, useSessionUpdate } from "./SessionContext";
import { useNotificationUpdate } from "./NotificationContext";
import { useMessageUpdate } from "./MessageContext";

import { useProfileUpdate } from "./ProfileContext";
import { usePromptUpdate } from "./PromptContext";

import { fetchLectureSlide } from "../services/fetch";

import ReportsService from "../services/reports";



export {
  PresentationContextProvider,
  usePresentation, usePresentationUpdate
};


const PresentationContext = createContext<any>( null ); // TODO: Properly fill out

const PresentationUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function usePresentation() {
  return useContext( PresentationContext );
}

function usePresentationUpdate() {
  return useContext( PresentationUpdateContext );
}



interface TaskItem {
  taskHandler: any;
  executeAt: number;

  goesLast?: boolean;

  onCompleteHandler?: () => void;
}


function PresentationContextProvider( {children}: ComponentProps ) {

  // ... Creating State
  const [ slideImageURL, setSlideImageURL ] = useState<string>( "https://www.ai4youth.org/assets/logo-809b144247fdd8ed03b6a5faf8585e0a25f412f941a3700ae64990215a3d79aa.png" );
  const [ slideCompleted, setSlideCompleted ] = useState<boolean>( false );

  const taskPriorityQueueRef = useRef<TaskItem[]>( [] );
  const isProcsesingTasks = useRef<boolean>( false );

  const instructorFollowUpTimeoutRef = useRef<NodeJS.Timeout | null>( null );


  const { slideCount, welcomeSlideURL } = useSession();
  const { getCurrentSlideNumber, updateCurrentSlideNumber, muteStudents, updateSessionPaused, getSessionPaused, setQuestionsAllowed, updateSlideContext, updateSessionPresentationContext, getDefaultAppStateHandlerConfig } = useSessionUpdate();

  const { cancelSpeechSynthesis } = useSpeechSynthesisUpdate();
  const { stopSpeechRecognition } = useSpeechRecognitionUpdate();

  const { postMessageAndSpeak, postMessageAndSpeakInChunks, stopAllSpeech } = useMessageUpdate();

  const { handleQuestion, handleGeneralPrompt } = usePromptUpdate();

  const { postNotification } = useNotificationUpdate();

  const { getHumanStudentName } = useProfileUpdate();



  useEffect( () => {
    displayWelcomeSlide();
  }, [welcomeSlideURL]);



  // TODO: Move this to higher level Context (and merge with "processQueue" from MessageContext ??? -- I.e. generalize and combine)
  const processTasks = useCallback( () => {
    if( isProcsesingTasks.current ) return;

    isProcsesingTasks.current = true;


    const intervalId = setInterval( async () => {
      const now = Date.now();
      const taskQueue = taskPriorityQueueRef.current;


      while( taskQueue.length && taskQueue[0].executeAt <= now ) {
        const task = taskQueue.shift();

        if( task ) {
          await task.taskHandler();

          if( task.onCompleteHandler ) {
            task.onCompleteHandler();
          }
        }
      }

      if( taskQueue.length === 0 ) {
        clearInterval( intervalId );
        isProcsesingTasks.current = false;
      }
    }, 1000 );

    return () => clearInterval( intervalId );
  }, [postMessageAndSpeakInChunks]);

  interface ScheduleTaskOptions {
    taskHandler: any;
    delayInMillis?: number;

    goesLast?: boolean;

    onCompleteHandler?: () => void;
  }
  const scheduleTask = useCallback( ({taskHandler, delayInMillis = 0, onCompleteHandler, goesLast = false}: ScheduleTaskOptions) => {
    const executeAt = Date.now() + delayInMillis;
    taskPriorityQueueRef.current.push({ taskHandler, executeAt, onCompleteHandler, goesLast });

    taskPriorityQueueRef.current.sort( (a, b) => {
      if( a.goesLast && !b.goesLast ) return 1;
      if( !a.goesLast && b.goesLast ) return -1;
      return a.executeAt - b.executeAt;
    }); // Soonest to execute is first (place goesLast tasks at the end)

    processTasks();
  }, [processTasks]);

  const clearTasks = useCallback( () => {
    taskPriorityQueueRef.current = [];
  }, []);



  useEffect( () => {
    processTasks();

    return () => {
      isProcsesingTasks.current = false;
      taskPriorityQueueRef.current = [];
    }
  }, [processTasks]);





  // ... Updating State


  const pausePresentation = useCallback( () => {
    ReportsService.reportPause();

    updateSessionPaused( true );
  }, []);

  const playPresentation = async () => {
    ReportsService.reportPlay();

    updateSessionPaused( false );

    //icon.classList.replace("fa-play", "fa-pause");
    //animateIcon(false);

    //const someoneWasSpeaking = someoneSpeaking;
    //resumeSpeechSynthesis(); // Plays any Utterances that were Queued while Paused
  }

  const togglePresentation = useCallback( () => {
    if( getSessionPaused() ) { // Play if Paused
      playPresentation();
    }
    else { // Pause if Playing
      //pausePresentation();
    }
  }, []);

  const goToPreviousSlide = useCallback( () => {
    ReportsService.reportPreviousClick(); // NOTE: Still will report even if unable to go to previous slide

    if( getSessionPaused() ) {
      postNotification("Click the slide to unpause before you can change slides");
      return;
    }

    if( getCurrentSlideNumber() <= 0 ) {
      postNotification("There are no prevous slides. You're already on the first.");
      return;
    }

    // Able Move On
    
    updateCurrentSlideNumber( getCurrentSlideNumber() - 1 );
    resetSlideState();

    if( getCurrentSlideNumber() == 0 ) {
      displayWelcomeSlide();
      return;
    }

    presentSlide();
  }, [slideCount]);

  const goToNextSlide = useCallback( () => {
      ReportsService.reportNextClick(); // NOTE: Still will report even if unable to go to next slide

      if( getSessionPaused() ) {
        postNotification( "Click the slide to unpause before you can change slides" );
        return;
      }

      if( getCurrentSlideNumber() >= slideCount ) {
        displayEndSlide();
        return;
      }

      // Able Move On
      updateCurrentSlideNumber( getCurrentSlideNumber() + 1 );
      resetSlideState();

      presentSlide();
  }, [slideCount]);

  const goToSlide = useCallback( (slideNumber: number) => {
    if( slideNumber < 0 || slideNumber > slideCount ) {
      postNotification("Invalid slide number");
      return;
    }

    updateCurrentSlideNumber( slideNumber );
    resetSlideState();

    presentSlide();
  }, [slideCount]);




  function resetSlideState() {
    //if( muteStudents() ) stopSpeechRecognition();
    setQuestionsAllowed( false );
    stopAllSpeech();

    clearTasks();

    instructorFollowUpTimeoutRef.current = null;
  }

  const setupQAndASlide = () => {
    setQuestionsAllowed( true );
  }

  const setupActivitySlide = () => {
    setQuestionsAllowed( true );
  }

  const setupQuizSlide = () => {
    setQuestionsAllowed( true );
  }




  const changeSlideImage = useCallback( (imageURL: string) => {

    if( !slideImageURL ) {
      // TODO: Error Handle
      console.error(`ERROR: Slide URL not found`);
      displayErrorSlideImage();
      return;
    }

    setSlideImageURL( imageURL );
  }, []);


  const displayErrorSlideImage = useCallback( () => {
    changeSlideImage( `${SERVER_ORIGIN}/${ERROR_OCCURRED_IMAGE}` );
  }, []);


  const displayWelcomeSlide = useCallback( () => {
    postNotification("This is the start of the Presentation. Press Play when you're ready.");

    changeSlideImage( welcomeSlideURL );

    // TODO: Currently not doing since PresentationContext has goToNextSlide only for if on isLectureStart and not for any other "Play" presses
    //pausePresentation();
  }, [welcomeSlideURL]);


  const displayEndSlide = useCallback( () => {
    postNotification( "That's the end of the presentation for now (:" );

    // TODO: Using welcomeSlideURL until Database supports endSlideImageURL field (then need add that variable to this file)
    // ... NOTE: May need keep in Session Context, since that is where we "setupLecture" and that's where I get that welcomeSlideURL (so that it varies based on Subject or Module)
    changeSlideImage( welcomeSlideURL );

    //AI4YouthNamespace.postMessageAndSpeakInChunks({ message: END_OF_PRESENTATION_MESSAGE });
  }, []);
  








  const parseTimeDelay = useCallback( (delayString: string): number | undefined => {
    if( delayString == null || typeof delayString !== "string" ) return;

    const match = delayString.match(/^(\d+)([smhd])$/); // Starts with Digit, followed by any of [s, m, h, or d], which ends it

    if( match ) {
      const numberPart = match[1]; // [0] is original String, then goes to each next part of String [1], [2], ...
      const unitPart = match[2];

      const value = parseInt(numberPart, 10); // Take numberPart, turn into Base 10

      switch (unitPart) {
        case "s":
          return value * ONE_SECOND_MILLIS;
        case "m":
          return value * ONE_MINUTE_MILLIS;
        case "h":
          return value * ONE_HOUR_MILLIS;
        case "d":
          return value * ONE_DAY_MILLIS;

        default: {
          console.warn("Unknown Time Unit");
          return;
        }
      }
    }

    console.warn("Invalid Delay Format");
    return;
  }, []);

  const removeCommentsFromSpeakerNotes = useCallback( (speakerNotes: string) => {
    return speakerNotes
      .split("\n")
      .filter(line => !line.trim().startsWith("#"))
      .join("\n");
  }, []);

  const replaceVariableReferencesInSpeakerNotes = useCallback( (speakerNotes: string) => {
    const studentName = getHumanStudentName();
    return speakerNotes.replaceAll("{STUDENT_NAME}", studentName);
  }, [getHumanStudentName]);

  const convertSmartQuotesToStandardQuotes = useCallback( (inputString: string) => { // Smart Quotes have direction to them, JSON expects standard Quotes without direction, i.e. opening and closing quotes should look identical
    return inputString
      .replace(/[\u2018\u2019]/g, "'") // Matches Left and Right Single Smart Quotes and replaces with standard Single Quotes
      .replace(/[\u201C\u201D]/g, '"'); // Matches Left and Right Double Smart Quotes and replaces with standard Double Quotes
  }, []);

  const removeTabsFromString = useCallback( (inputString: string) => {
    return inputString.replace(/\t/g, ""); // Removes all Tab characters (\t) from the string
  }, []);

  const parseSpeakerNotesAsJSONString = useCallback( (speakerNotesAsJSON: any) => { // TODO: TYPE

    speakerNotesAsJSON = removeCommentsFromSpeakerNotes(speakerNotesAsJSON);
    speakerNotesAsJSON = convertSmartQuotesToStandardQuotes(speakerNotesAsJSON);
    speakerNotesAsJSON = removeTabsFromString(speakerNotesAsJSON);

    speakerNotesAsJSON = speakerNotesAsJSON.replace( /\n/g, " " ).replace( /\r/g, " " );

    // Match a Comma that is followed by any # of Spaces and then a Curley Brace } or a Square Bracket ] (escaped as \] due to that being special Regex Character to "Group") -- Make this a Capturing Group (referenced as $1), replacing comma with whatever series of Characters captured by Capturing Group
    const cleanedString = speakerNotesAsJSON.replace(/,(\s*[}\]])/g, '$1');

    return JSON.parse(cleanedString);
  }, []);


  const parseSpeakerNotesJSONSection = useCallback( (speakerNotesJSON: any, sectionName: string) => { // TODO: TYPE
    if( speakerNotesJSON.hasOwnProperty(sectionName) ) return speakerNotesJSON[sectionName]
    else return null;
  }, []);

  const parseSpeakerNotesSection = useCallback( (speakerNotesParts: string[], sectionIndex: number) => {
    if (speakerNotesParts.length <= sectionIndex) return null; // The part doesn't exist in this Slide's Speaker Notes

    const sectionText = speakerNotesParts[sectionIndex];

    return sectionText ? sectionText.trim() : null;
  }, []);



  /*
  const delayedPostMessageAndSpeak = useCallback( (messageOptions: any, delayInSeconds: number) => {

    if( instructorFollowUpTimeoutRef.current ) {
      clearTimeout( instructorFollowUpTimeoutRef.current );
    }

    instructorFollowUpTimeoutRef.current = setTimeout( async () => {
      console.log("ELAPSED...");
      await postMessageAndSpeakInChunks( messageOptions );
    }, delayInSeconds * 1000 );

  }, [postMessageAndSpeakInChunks]);
  */







  const presentSlide = useCallback( async () => {
      setSlideCompleted( false );

      try {
          // TODO: CACHE THIS ON CLIENT ?? -- Server may need send Cache Invalidor information but MUST last length of Session at least
          const slideData = await fetchLectureSlide( getCurrentSlideNumber(), getDefaultAppStateHandlerConfig() );

          if( !slideData ) {
            displayErrorSlideImage();
            return;
          }
          else {
            changeSlideImage( slideData.thumbnailURL );
          }

          const rawSpeakerNotes = slideData.speakerNotes;
          if( rawSpeakerNotes == null ) return; // No Speaker Notes found for this Slide


          // ############################# SPEAKER NOTES PARSING #############################
          let contextText: string;
          let lectureText: string;
          let followUpText: string;
          let lecturePrompt: string;

          let fakeStudentFollowUpText: string;
          let instructorFollowUpDelayString: string;
          let fakeStudentFollowUpDelayString: string;
          let slideMoveOnDelayString: string;
          let maxSilenceGapString: string;
          let backgroundPrompt: string;
          let backgroundPromptDelayString: string;
 
          const speakerNotes = replaceVariableReferencesInSpeakerNotes( rawSpeakerNotes );
          const speakerNotesJSON = parseSpeakerNotesAsJSONString( speakerNotes );

          if( DEBUG && VERBOSE ) console.log( speakerNotesJSON );

          // TODO: Expects at LEAST Slide Context and Lecture Text, but what if only Lecture Text? -- MORE ROBUST ERROR HANDLING
          // Currently expecting at least Context and Lecture Texts. The rest is not enforced but important for the user-experience.
          contextText = parseSpeakerNotesJSONSection( speakerNotesJSON, "context" );
          lectureText = parseSpeakerNotesJSONSection( speakerNotesJSON, "lecture" );
          lecturePrompt = parseSpeakerNotesJSONSection( speakerNotesJSON, "lecturePrompt" );

          followUpText = parseSpeakerNotesJSONSection( speakerNotesJSON, "followUp" );
          fakeStudentFollowUpText = parseSpeakerNotesJSONSection( speakerNotesJSON, "fakeStudentFollowUpText" );
          instructorFollowUpDelayString = parseSpeakerNotesJSONSection( speakerNotesJSON, "instructorFollowUpDelay" );
          fakeStudentFollowUpDelayString = parseSpeakerNotesJSONSection( speakerNotesJSON, "fakeStudentFollowUpDelay" );
          slideMoveOnDelayString = parseSpeakerNotesJSONSection( speakerNotesJSON, "slideMoveOnDelay" );
          maxSilenceGapString = parseSpeakerNotesJSONSection( speakerNotesJSON, "maxSilenceGap" );
          backgroundPrompt = parseSpeakerNotesJSONSection( speakerNotesJSON, "backgroundPrompt" );
          backgroundPromptDelayString = parseSpeakerNotesJSONSection( speakerNotesJSON, "backgroundPromptDelay" );

          const maxSilenceGap = parseTimeDelay(maxSilenceGapString) ? parseTimeDelay(maxSilenceGapString) : MIN_SILENCE_BEFORE_NEXT_SLIDE; // Calling twice cuz other conditions may return NULL, all of which want to make sure apply Default

          const slideMoveOnDelay = parseTimeDelay(slideMoveOnDelayString) ? parseTimeDelay(slideMoveOnDelayString) : DEFAULT_SLIDE_MOVE_ON_DELAY;
          const instructorFollowUpDelay = parseTimeDelay(instructorFollowUpDelayString) ? parseTimeDelay(instructorFollowUpDelayString) : DEFAULT_INSTRUCTOR_FOLLOW_UP_DELAY;
          const fakeStudentFollowUpDelay = parseTimeDelay(fakeStudentFollowUpDelayString) ? parseTimeDelay(fakeStudentFollowUpDelayString) : DEFAULT_FAKE_STUDENT_FOLLOW_UP_DELAY;
          const backgroundPromptDelay = parseTimeDelay(backgroundPromptDelayString) ? parseTimeDelay(backgroundPromptDelayString) : DEFAULT_BACKGROUND_PROMPT_DELAY;
          // ##################################################################################

          //if( DEBUG && VERBOSE ) {
          if( DEBUG ) {
              console.groupCollapsed("Speaker Notes Text");
              console.log(`CONTEXT: ${contextText}`);
              console.log(`LECTURE NOTES: ${lectureText}`);
              console.log(`FOLLOW-UP: ${followUpText} --- ${instructorFollowUpDelay}`);
              console.log(`FAKE STUDENT FOLLOW UP TEXT: ${fakeStudentFollowUpText} --- ${fakeStudentFollowUpDelay}`);
              console.log(`SLIDE MOVE ON DELAY: ${slideMoveOnDelay}`);
              console.log(`MAX SILENCE GAP: ${maxSilenceGap}`);
              console.groupEnd();
          }

          const isQAndASlide = contextText.toLowerCase().includes( Q_AND_A_SLIDE_KEY_PHRASE.toLowerCase() );
          if( isQAndASlide ) {
            postNotification( "This is a Q&A Slide" );
            setupQAndASlide();
          }

          const isActivitySlide = contextText.toLowerCase().includes( ACTIVITY_SLIDE_KEY_PHRASE.toLowerCase() );
          if( isActivitySlide ) {
            postNotification( "This is an Activity Slide" );
            setupActivitySlide();
          }

          const isQuizSlide = contextText.toLowerCase().includes( QUIZ_SLIDE_KEY_PHRASE.toLowerCase() );
          if( isQuizSlide ) {
            postNotification( "This is a Quiz Slide" );
            setupQuizSlide();
          }


          updateSlideContext( contextText );
          updateSessionPresentationContext( contextText );


          const onLectureCompleteHandler = () => {
            const handleLecturePrompt = async () => {
              const response = await handleGeneralPrompt( lecturePrompt );

              const messageOptions = { message: response, sender: INSTRUCTOR_NAME, type: MessageType.CHAT };
              await postMessageAndSpeakInChunks( messageOptions );
            }

            const onFakeStudentFollowUpCompleteHandler = () => {
              if( backgroundPrompt ) { // BACKGROUND PROMPT
                const handleBackgroundPrompt = async () => {  
                  const response = await handleGeneralPrompt( backgroundPrompt );

                  const messageOptions = { message: response, sender: INSTRUCTOR_NAME, type: MessageType.CHAT };
                  await postMessageAndSpeakInChunks( messageOptions );
                }

                scheduleTask({ taskHandler: handleBackgroundPrompt, delayInMillis: backgroundPromptDelay });
              }

              if( followUpText ) { // INSTRUCTOR FOLLOW-UP
                const handleInstructorFollowUp = async () => {
                  const messageOptions = { message: followUpText, sender: INSTRUCTOR_NAME, type: MessageType.CHAT, addToTranscript: false };
                  await postMessageAndSpeakInChunks( messageOptions );
                }

                scheduleTask({ taskHandler: handleInstructorFollowUp, delayInMillis: instructorFollowUpDelay });
              }
            }


            // TODO: Make FakeStudentPrompt come after this...
            if( lecturePrompt ) {
              scheduleTask({ taskHandler: handleLecturePrompt, delayInMillis: 0 }); // Always do immediately
            }

            if( fakeStudentFollowUpText ) { // FAKE STUDENT FOLLOW-UP
              const handleFakeStudentFollowUp = async () => {
                let messageOptions = { message: fakeStudentFollowUpText, sender: FAKE_STUDENT_NAME, type: MessageType.CHAT };
                await postMessageAndSpeakInChunks( messageOptions );

                const response = await handleQuestion( fakeStudentFollowUpText, FAKE_STUDENT_NAME );

                messageOptions = { message: response, sender: INSTRUCTOR_NAME, type: MessageType.CHAT };
                await postMessageAndSpeakInChunks( messageOptions );
              }

              scheduleTask({ taskHandler: handleFakeStudentFollowUp, delayInMillis: fakeStudentFollowUpDelay, onCompleteHandler: onFakeStudentFollowUpCompleteHandler });
            }
            else {
              onFakeStudentFollowUpCompleteHandler();
            }

            // TODO: This is more of a hack, and doesn't work fool-proof for guaranteed "at end of slide", since not know exactly which will end first, Background Prompt or Instructor Follow-Up
            scheduleTask({ taskHandler: () => setSlideCompleted(true), delayInMillis: 10000, goesLast: true }); //, delayInMillis: slideMoveOnDelay );
          }

          if( lectureText ) {
            const messageOptions = { message: lectureText, sender: INSTRUCTOR_NAME, type: MessageType.CHAT, addToTranscript: false }; // TODO: HARD-CODED "CHAT" since haven't updated code to handle Presentation Mode (i.e. Lecture Message Box not exist)
            await postMessageAndSpeakInChunks( messageOptions, { unmuteAfter: false }, onLectureCompleteHandler);
          }
          else {
            onLectureCompleteHandler();
          }
      } catch( error ) {
          console.error("FAILED to Present Slide: ", error);

          const crashDetails = error;
          ReportsService.reportCrash( crashDetails );
      }
  }, [replaceVariableReferencesInSpeakerNotes, parseSpeakerNotesAsJSONString, parseSpeakerNotesJSONSection, parseSpeakerNotesSection, postMessageAndSpeakInChunks, scheduleTask] );










  // ... Persisting State down into Children
  return (
    <PresentationContext.Provider value={ { slideImageURL, slideCompleted } } >
      <PresentationUpdateContext.Provider value={ { togglePresentation, goToPreviousSlide, goToNextSlide, goToSlide } } >
        {children}
      </PresentationUpdateContext.Provider>
    </PresentationContext.Provider>
  );


}
