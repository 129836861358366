


import { useRef, useState } from "react";

import { NAVIGATION_BUTTON_DEBOUNCE_DELAY } from "../common/constants";

import { usePresentation, usePresentationUpdate } from "../context/PresentationContext";
import { useSpeechSynthesisUpdate } from "../context/SpeechSynthesisContext";
import { useSession, useSessionUpdate } from "../context/SessionContext";
import { useNotificationUpdate } from "../context/NotificationContext";

import { useSpeechRecognition } from "react-speech-recognition";




export default function PresentationContainer() {

  const [ isAtLectureStart, setIsLectureStart ] = useState<boolean>( true );
  //const [ isPaused, setIsPaused ] = useState<boolean>( true ); // TODO: Reference useSession's State instead? -- May want to know it's paused across entire App, not just this Component (same for LecutreContainer.tsx)

  const { isMuted, slideCount, sessionPaused } = useSession();
  const { muteStudents, unmuteStudents, getCurrentSlideNumber, updateSessionPaused, getSessionPaused } = useSessionUpdate();

  const { slideImageURL, slideCompleted } = usePresentation();
  const { togglePresentation, goToPreviousSlide, goToNextSlide, goToSlide } = usePresentationUpdate();

  const { postNotification } = useNotificationUpdate();

  const { pauseSpeechSynthesis, resumeSpeechSynthesis } = useSpeechSynthesisUpdate();

  const [ slideNumberInputValue, setSlideNumberInputValue ] = useState<number | undefined>();



  const {
    transcript,
    resetTranscript: resetAudioTranscript,
  } = useSpeechRecognition();
 


  const activatePresentation = () => {
    //setIsPaused( false );
    updateSessionPaused( false );
    setIsLectureStart( false );
  }


  const handleStartLecture = async ( event: any ) => {
    activatePresentation();

    goToNextSlide();
  }


  const displayLecturePausedNotification = () => {
    const actionButton = getCurrentSlideNumber() <= 0 ? "Start" : "Play";
    postNotification( `Lecture is paused. Please press ${actionButton} to change slides.` );
  }

  const displaySlideNotCompletedNotification = ( direction: "next" | "previous" ) => {
    postNotification( `Please wait for slide to complete before moving to ${direction} slide.` );
  }

  //const handleGoToPreviousSlide = ( event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) {
  const handleGoToPreviousSlide = async ( event: any ) => { // TODO: TYPE ("event.target.disabled" complains w/ MouseEvent<HTMLButtonElement,...>)
    //if( isPaused ) {
    if( getSessionPaused() ) {
      displayLecturePausedNotification();
      return;
    }
    if( !slideCompleted ) {
      displaySlideNotCompletedNotification( "previous" );
      return;
    }

    //event.target.disabled = true;

    goToPreviousSlide();
    setSlideNumberInputValue( getCurrentSlideNumber() );

    setTimeout(() => { // HACK: DEBOUNCE -- Prevent multiple presses which prevents strange Browser Speech Synthesis API issue where text from previous slides will be spoken even after changing slide (when done in rapid succession)
      //event.target.disabled = false;
    }, NAVIGATION_BUTTON_DEBOUNCE_DELAY);
  }

  //const handleGoToNextSlide = async ( event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
  const handleGoToNextSlide = async ( event: any ) => { // TODO: TYPE ("event.target.disabled" complains w/ MouseEvent<HTMLButtonElement,...>)
    //if( isPaused ) {
    if( getSessionPaused() ) {
      displayLecturePausedNotification();
      return;
    }
    if( !slideCompleted ) {
      displaySlideNotCompletedNotification( "next" );
      return;
    }

    //event.target.disabled = true;

    goToNextSlide();
    setSlideNumberInputValue( getCurrentSlideNumber() );

    setTimeout(() => { // HACK: DEBOUNCE -- Prevent multiple presses which prevents strange Browser Speech Synthesis API issue where text from previous slides will be spoken even after changing slide (when done in rapid succession)
      //event.target.disabled = false;
    }, NAVIGATION_BUTTON_DEBOUNCE_DELAY);
  }

  const handleGoToSlideEnterPress = ( event: any ) => {
    if( event.key === "Enter" ) {
      event.target.disabled = true;
      event.target.classList.add("disabled");

      const slideNumber = event.target.value;
      handleGoToSlide( parseInt(slideNumber) );

      setTimeout(() => {
        event.target.disabled = false;
        event.target.classList.remove("disabled");
      }, NAVIGATION_BUTTON_DEBOUNCE_DELAY);
    }
  }
  const handleGoToSlideButtonPress = ( event: any ) => {
    event.target.disabled = true;
    event.target.classList.add("disabled");

    handleGoToSlide( slideNumberInputValue );

    setTimeout(() => {
      event.target.disabled = false;
      event.target.classList.remove("disabled");
    }, NAVIGATION_BUTTON_DEBOUNCE_DELAY);
  }
  const handleGoToSlide = ( slideNumber: number | undefined ) => {
    if( !slideNumber ) {
      postNotification("Invalid slide number");
      return;
    };

    goToSlide( slideNumber );
    activatePresentation();
  }

  const handleSlideNumberInputChange = ( event: any ) => {
    setSlideNumberInputValue( parseInt(event.target.value) );
  }



  // TODO: handleContinueLecture,togglePause are DUPLICATED(ish) here & in PresentationContainer
  // ... CONSIDER generalizing to a "ContentDeliveryContext" and organizing better
  const togglePause = () => {

    // TODO: When pause, students will be UNMUTED because pauseSpeechSynthesis UNMUTES STUDENTS
    //if( isPaused ) { // Intending to "Play"
    if( getSessionPaused() ) { // Intending to "Play"
      resumeSpeechSynthesis();
      updateSessionPaused( false );
    }
    else { // Intending to "Pause"
      pauseSpeechSynthesis();
      updateSessionPaused( true );
      muteStudents();
    }

    //setIsPaused( !isPaused );
    
    // TOOD: Make some general function (e.g. in SessionContext) to handle this
    /*
    if( isMuted ) unmuteStudents();
    else {
      muteStudents();
      console.log("AUDIO TRANSCRIPT (TOGGLE PAUSE)...", transcript);
      resetAudioTranscript();
    }
    */
    if( !isMuted ) {
      //console.log("AUDIO TRANSCRIPT (TOGGLE PAUSE)...", transcript);
      resetAudioTranscript();
    }
  }



  return (
    <div id="slides-container" className="slides-container">

      {/* TODO: Change Placeholder Image to something else? */}
      <div className="slide-image-container">
        {/*<img id="slide-image" className="slide-image" alt="Slide Visual" src="https://www.ai4youth.org/assets/logo-809b144247fdd8ed03b6a5faf8585e0a25f412f941a3700ae64990215a3d79aa.png" />*/}
        <img id="slide-image" className="slide-image" alt="Slide Visual" src={slideImageURL} />
        {/* TODO: Come back to once get things working as desired and ready add Pause/Play Icon
        <div id="overlay" className="overlay">
          <i id="icon" className="fas fa-play"></i>
        </div>
        */}

        <span id="slide-number" className="slide-number">{getCurrentSlideNumber()}/{slideCount}</span>
      </div>

      <div className="slide-footer-container">
        <div className="navigation-container">
          <button className={`navigation-button ${!slideCompleted ? "disabled" : ""}`} type="button" onClick={handleGoToPreviousSlide}>Back</button>
          {/*<button id="presentation-control-button" className="navigation-button presentation-control-button" type="button" onClick={ (event) => isAtLectureStart ? handleStartLecture(event) : togglePause() }>{isPaused ? (isAtLectureStart ? "Start" : "Play") : "Pause"}</button>*/}
          <button id="presentation-control-button" className="navigation-button presentation-control-button" type="button" onClick={ (event) => isAtLectureStart ? handleStartLecture(event) : togglePause() }>{getSessionPaused() ? (isAtLectureStart ? "Start" : "Play") : "Pause"}</button>
          <button className={`navigation-button ${!slideCompleted ? "disabled" : ""}`} type="button" onClick={handleGoToNextSlide}>Next</button>
        </div>

        <div className="slide-number-input-container">
          <input className="slide-number-input" type="number" placeholder="Slide Number" value={isNaN(slideNumberInputValue as number) ? "" : slideNumberInputValue} onChange={handleSlideNumberInputChange} onKeyDown={handleGoToSlideEnterPress} />
          <button className="navigation-button slide-number-input-button" type="button" onClick={handleGoToSlideButtonPress}>Go</button>
        </div>

        {/*
        <div id="slideTimerDisplay">00:00</div>
        */}
      </div>
    </div>
  );
}



