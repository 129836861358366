

function fromCamelCaseToCapitalizedSpace( str: string ) {
    // Need to make it capitalize the first letter of each word
  return str.replace( /([A-Z])/g, " $1" )
            .trim()
            .replace(/\b\w/g, (match: string) => match.toUpperCase()); ;
}




export {
    fromCamelCaseToCapitalizedSpace
};

