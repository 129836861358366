


import { SERVER_ORIGIN } from "../common/constants";
import { SpeakerType } from "../types/enums";

import ReportsService from "./reports";
import RetryHandler from "./retryHandler";


interface HandlerConfig {
  onRetry?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
}



async function fetchLectureInfo( handlerConfig?: HandlerConfig ) {

  try {
    const response = await RetryHandler.getInstance().executeWithRetry( async () => {
      const response = await fetch(`${SERVER_ORIGIN}/api/lectures/lecture`, {
        method: "GET",
        credentials: "include",
      });

      if( !response.ok  ) {
        throw new Error( "Error Fetching Lecture Info... " + response.status );
      }

      return response;
    }, {
      maxRetries: RetryHandler.DEFAULT_MAX_RETRIES,
      initialDelay: RetryHandler.DEFAULT_INITIAL_DELAY,
      maxDelay: RetryHandler.DEFAULT_MAX_DELAY,
      shouldRetry: (error: any) => {
        return true; // TODO: Decide if not want to always retry...
      },

      handlerConfig
    });

    if( !response.ok ) {
      const contentType = response.headers.get("Content-Type");
      const crashDetails = contentType?.includes("application/json") ? await response.json() : await response.text()
      ReportsService.reportCrash( crashDetails );

      return;
    }

    return await response.json();
  }
  catch( error ) {
    const errorMessage = "Error Fetching Lecture Info... " + error;
    console.error( errorMessage );

    ReportsService.reportCrash({ error: errorMessage });

    return;
  }
}


async function fetchLecturePage( pageNumber: number, handlerConfig?: HandlerConfig ) {

  /*
  const response = await fetch(`${SERVER_ORIGIN}/api/lectures/written-lecture/${pageNumber}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  const page = data.pages[0]; // TODO: ERROR HANDLE

  return page;
  */

  try {
    const response = await RetryHandler.getInstance().executeWithRetry( async () => {
      const response = await fetch(`${SERVER_ORIGIN}/api/lectures/written-lecture/${pageNumber}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if( !response.ok ) {
        throw new Error( "Error Fetching Lecture Page... " + response.status );
      }

      return response;
    }, {
      maxRetries: RetryHandler.DEFAULT_MAX_RETRIES,
      initialDelay: RetryHandler.DEFAULT_INITIAL_DELAY,
      maxDelay: RetryHandler.DEFAULT_MAX_DELAY,
      shouldRetry: (error: any) => {
        return true; // TODO: Decide if not want to always retry...
      },

      handlerConfig
    });

    if( !response.ok ) {
      const contentType = response.headers.get("Content-Type");
      const crashDetails = contentType?.includes("application/json") ? await response.json() : await response.text()
      ReportsService.reportCrash( crashDetails );

      return;
    }

    const data = await response.json();
    const page = data.pages[0]; // TODO: ERROR HANDLE

    return page;
  }
  catch( error ) {
    const errorMessage = "Error Fetching Lecture Info... " + error;
    console.error( errorMessage );

    ReportsService.reportCrash({ error: errorMessage });

    return;
  }
}

async function fetchLectureSlide( slideNumber: number, handlerConfig?: HandlerConfig ) {

  try {
    const response = await RetryHandler.getInstance().executeWithRetry( async () => {
      const response = await fetch( `${SERVER_ORIGIN}/api/lectures/lecture/${slideNumber}`, {
        method: "GET",
        credentials: "include",
      })

      if( !response.ok ) {
        throw new Error( "Error Fetching Lecture Slide... " + response.status );
      }

      return response;
    }, {
      maxRetries: RetryHandler.DEFAULT_MAX_RETRIES,
      initialDelay: RetryHandler.DEFAULT_INITIAL_DELAY,
      maxDelay: RetryHandler.DEFAULT_MAX_DELAY,
      shouldRetry: (error: any) => {
        return true; // TODO: Decide if not want to always retry...
      },

      handlerConfig
    } );

    if( !response.ok ) {
      const contentType = response.headers.get("Content-Type");
      const crashDetails = contentType?.includes("application/json") ? await response.json() : await response.text()
      ReportsService.reportCrash( crashDetails );
      return;
    }

    const slideData = await response.json();

    return slideData;
  }
  catch( error ) {
    const errorMessage = "Error Fetching Lecture Slide... " + error;
    console.error( errorMessage );

    ReportsService.reportCrash({ error: errorMessage });

    return;
  } 
}



async function fetchModelResponse( promptType: string, context: string, prompt: string, handlerConfig?: HandlerConfig ) {

  try {
    const response = await RetryHandler.getInstance().executeWithRetry( async () => {
      const response = await fetch(`${SERVER_ORIGIN}/api/completions?type=${promptType}`, {
        method: 'POST',
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },

        body: JSON.stringify({ "context": context, "prompt": prompt })
      });

      if( !response.ok ) {
        throw new Error( "Error Fetching Model Response... " + response.status );
      }

      return response;
    }, {
      maxRetries: RetryHandler.DEFAULT_MAX_RETRIES,
      initialDelay: RetryHandler.DEFAULT_INITIAL_DELAY,
      maxDelay: RetryHandler.DEFAULT_MAX_DELAY,
      shouldRetry: (error: any) => {
        return true; // TODO: Decide if not want to always retry...
      },

      handlerConfig
    }); 

    if (!response.ok) {
      const contentType = response.headers.get("Content-Type");
      const crashDetails = contentType?.includes("application/json") ? await response.json() : await response.text()
      ReportsService.reportCrash( crashDetails );

      return;
    }

    return await response.json();
  }
  catch( error ) {
    const errorMessage = "Error Fetching Completions... " + error;
    console.error( errorMessage );

    ReportsService.reportCrash({ error: errorMessage });

    return;
  }
}


async function fetchAssistantResponse( promptType: string, context: string, prompt: string, file: File, handlerConfig?: HandlerConfig ) {

  try {
    const formData = new FormData();

    if( file ) {
      formData.append( "file", file );
    }

    formData.append( "context", context );
    formData.append( "prompt", prompt );

    const response = await RetryHandler.getInstance().executeWithRetry( async () => {
      const response = await fetch(`${SERVER_ORIGIN}/api/completions/assistants/threads/messages?type=${promptType}`, {
        method: 'POST',
        credentials: "include",
        headers: {
          //"Content-Type": "application/json"
        },

        body: formData,
      });

      if( !response.ok ) {
        throw new Error( "Error Fetching Assistant Response... " + response.status );
      }

      return response;
    }, {
      maxRetries: RetryHandler.DEFAULT_MAX_RETRIES,
      initialDelay: RetryHandler.DEFAULT_INITIAL_DELAY,
      maxDelay: RetryHandler.DEFAULT_MAX_DELAY,
      shouldRetry: (error: any) => {
        return true; // TODO: Decide if not want to always retry...
      },

      handlerConfig
    });

    if( !response.ok ) {
      const contentType = response.headers.get("Content-Type");
      const crashDetails = contentType?.includes("application/json") ? await response.json() : await response.text()
      ReportsService.reportCrash( crashDetails );

      return;
    }

    return await response.json();
  }
  catch( error ) {
    const errorMessage = "Error Fetching Completions... " + error;
    console.error( errorMessage );

    ReportsService.reportCrash({ error: errorMessage });

    return;
  }
}


async function fetchOpenAITextToSpeech( text: string, voiceName: string, handlerConfig?: HandlerConfig ) {

  try {
    const response = await RetryHandler.getInstance().executeWithRetry( async () => {
      const response = await fetch(`${SERVER_ORIGIN}/api/synthetics/speech/convert?format=binary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ textToConvert: text, voiceName })
      });

      if( !response.ok ) {
        throw new Error( "Error Fetching OpenAI Text To Speech... " + response.status );
      }

      return response;
    }, {
      maxRetries: RetryHandler.DEFAULT_MAX_RETRIES,
      initialDelay: RetryHandler.DEFAULT_INITIAL_DELAY,
      maxDelay: RetryHandler.DEFAULT_MAX_DELAY,
      shouldRetry: (error: any) => {
        return true; // TODO: Decide if not want to always retry...
      },

      handlerConfig
    });

    if( !response.ok ) {
      const contentType = response.headers.get("Content-Type");
      const crashDetails = contentType?.includes("application/json") ? await response.json() : await response.text()
      ReportsService.reportCrash( crashDetails );

      return;
    }

    return await response.blob();
  }
  catch( error ) {
    const errorMessage = "Error Fetching OpenAI Text To Speech... " + error;
    console.error( errorMessage );

    ReportsService.reportCrash({ error: errorMessage });

    return;
  }
}





export {
  fetchLectureInfo,

  fetchLecturePage,
  fetchLectureSlide,

  fetchModelResponse,
  fetchAssistantResponse,

  fetchOpenAITextToSpeech,
}
