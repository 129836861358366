
import TimeService from "./time";


interface RetryHandlerConfig {
  onRetry?: () => void;
  onFailure?: () => void;
  onSuccess?: () => void;
}

interface RetryOptions {
  maxRetries?: number;
  initialDelay?: number; // Initial delay in milliseconds
  maxDelay?: number; // Max delay in milliseconds
  shouldRetry?: (error: any) => boolean; // Function to determine if retry is needed

  handlerConfig?: RetryHandlerConfig;
}

export default class RetryHandler {
  private static instance: RetryHandler;

  public static DEFAULT_MAX_RETRIES = 3;
  public static DEFAULT_INITIAL_DELAY = 2000;
  public static DEFAULT_MAX_DELAY = 20000;

  private constructor() {}

  public static getInstance(): RetryHandler {
    if (!this.instance) {
      this.instance = new RetryHandler();
    }
    return this.instance;
  }

  public async executeWithRetry<T>(operation: () => Promise<T>, options: RetryOptions = {}): Promise<T> {
    const maxRetries = options.maxRetries ?? RetryHandler.DEFAULT_MAX_RETRIES;
    const initialDelay = options.initialDelay ?? RetryHandler.DEFAULT_INITIAL_DELAY;
    const maxDelay = options.maxDelay ?? RetryHandler.DEFAULT_MAX_DELAY;
    const shouldRetry = options.shouldRetry ?? this.defaultShouldRetry;

    const onRetry = options.handlerConfig?.onRetry ?? (() => {});
    const onFailure = options.handlerConfig?.onFailure ?? (() => {});
    const onSuccess = options.handlerConfig?.onSuccess ?? (() => {});

    let attempts = 0;

    while (attempts <= maxRetries) {
      try {
        const result = await operation();

        onSuccess();

        return result;
      } catch( error: any ) {
        attempts++;
        if( !shouldRetry(error) || attempts > maxRetries ) {
          onFailure();
          throw error;
        }

        onRetry();

        const delay = Math.min(initialDelay * Math.pow(2, attempts) + Math.random() * 1000, maxDelay);
        console.warn(`Operation failed, retrying in ${delay}ms (attempt ${attempts})...`, error?.message);
        await TimeService.delay(delay);
      }
    }

    throw new Error("Operation failed after maximum retries.");
  }

  private defaultShouldRetry(error: any): boolean {
    if (error?.response?.status) {
      const status = error.response.status;
      return status === 429; // Retry on 429 (Too Many Requests)
    }
    return false;
  }
}
