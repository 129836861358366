
import "../css/admin.css";


import { useEffect, useState } from "react";

import { DEBUG, ENVIRONMENT, PROD } from "../common/constants";

import { fromCamelCaseToCapitalizedSpace } from "../common/helpers";

import { useAdmin, useAdminUpdate } from "../context/AdminContext";
import { PromptTemplateType, usePrompt, usePromptUpdate } from "../context/PromptContext";
import { useCurriculum, useCurriculumUpdate } from "../context/CurriculumContext";




function AdminToggle() {
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const { toggleAdminMode } = useAdminUpdate();

    return (
        <div
            className="admin-toggle"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <button
                onClick={toggleAdminMode}
                className={`admin-toggle-button ${isHovered ? "admin-toggle-button-hover" : ""}`}
            >
                ⚙️
            </button>
            {isHovered && (
                <div className="admin-tooltip">
                    Admin Panel
                </div>
            )}
        </div>
    );
}



function Overlay({ onClose, children }: { onClose: () => void, children: React.ReactNode }) {

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if( e.key === "Escape" ) {
                onClose();
            }
        };

        document.addEventListener( "keydown", handleKeyDown );

        return () => document.removeEventListener( "keydown", handleKeyDown );
    }, [onClose]);

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if( e.target === e.currentTarget ) {
            onClose();
        }
    };

    return (
        <div className="overlay-container" onClick={handleOverlayClick}>
            {children}
        </div>
    );
}

function AdminOverlay() {
    const { toggleAdminMode } = useAdminUpdate();

    return (
        <Overlay onClose={toggleAdminMode}>
            <div className="admin-panel-container">
                <button
                    onClick={toggleAdminMode}
                    className="admin-close-button"
                >
                    ✕
                </button>
                <AdminPanel />
            </div>
        </Overlay>
    );
}

function AdminPanel() {

    const [ selectedCategory, setSelectedCategory ] = useState<string>("lecture");

    const { selectedSection, selectedSubsection, sections } = useAdmin();
    const { changeSelectedSection, changeSelectedSubsection } = useAdminUpdate();

    const { getPromptCategories, getPromptTemplatesForCategory, updatePromptTemplate } = usePromptUpdate();



    const adminSupported = ENVIRONMENT != PROD || DEBUG;
    if( !adminSupported ) {
        return null;
    }

    return (
        <div className="admin-panel">
            <div className="admin-sidebar">
                <h2 className="admin-sidebar-title">Configuration</h2>

                <div className="admin-section-selector">
                    <select
                        value={selectedSection}
                        onChange={(e) => {
                            changeSelectedSection(e.target.value);
                            changeSelectedSubsection(Object.keys(sections[e.target.value].subsections)[0]);
                        }}
                        className="admin-section-dropdown"
                    >
                        {Object.values(sections).map((section) => (
                            <option key={section.id} value={section.id}>
                                {section.label}
                            </option>
                        ))}
                    </select>
                </div>
                
                <div className="admin-subsection-nav">
                    {Object.entries(sections[selectedSection].subsections).map(([key, label]) => (
                        <button
                        key={key}
                        onClick={() => changeSelectedSubsection(key)}
                        className={`admin-sidebar-button ${
                            selectedSubsection === key ? 'admin-sidebar-button-active' : ''
                        }`}
                        >
                        ▸ {fromCamelCaseToCapitalizedSpace(label)}
                        </button>
                    ))}
                </div>

                {/*getPromptCategories().map((category: string) => (
                    <button
                        key={category}
                        onClick={() => setSelectedCategory(category)}
                        className={`admin-sidebar-button ${selectedCategory === category ? "admin-sidebar-button-active" : ""
                            }`}
                    >
                        ▸ {fromCamelCaseToCapitalizedSpace(category)}
                    </button>
                ))*/}
            </div>

            <div className="admin-content">
                <AdminPanelContent section={selectedSection} subsection={selectedSubsection} />
                {/*
                <h3 className="admin-content-title">
                    {fromCamelCaseToCapitalizedSpace(selectedCategory)}
                </h3>
                */}

                {/*getPromptTemplatesForCategory(selectedCategory).map((promptTemplate: any) => ( //PromptTemplate) => ( // TODO: TYPE
                    <div key={promptTemplate.id} className="admin-input-container">
                        <label className="admin-input-label">
                            {promptTemplate.name}
                        </label>
                        <textarea
                            value={promptTemplate.template}
                            onChange={(e) => updatePromptTemplate(promptTemplate.id, e.target.value)}
                            className="admin-input-textarea"
                        />
                    </div>
                ))*/}
            </div>
        </div>
    );
}







/* ============================== HELPERS ============================== */


interface ContentRendererProps {
    children: React.ReactNode;
    title: string;
    description?: string;
}
function ContentRenderer({ title, description, children }: ContentRendererProps) {
    return (
        <div className="admin-content-section">
            <h3 className="admin-content-title">{title}</h3>
            {description && <p className="admin-content-description">{description}</p>}
            {children}
        </div>
    );
}


// ============================== PROMPTS ==============================
function PromptsContent({ subsection }: { subsection: string }) {
    const { getPromptTemplatesForCategory, updatePromptTemplate } = usePromptUpdate();

    return (
        <ContentRenderer
            title={`Prompt Templates - ${fromCamelCaseToCapitalizedSpace(subsection)}`}
            description="Customize the AI's behavior by modifying these prompt templates."
        >
            {getPromptTemplatesForCategory(subsection).map((promptTemplate) => (
                <div key={promptTemplate.id} className="admin-input-container">
                    <label className="admin-input-label">
                        {promptTemplate.name}
                        {promptTemplate.description && (
                            <span className="admin-input-description">{promptTemplate.description}</span>
                        )}
                    </label>
                    <textarea
                        value={promptTemplate.template}
                        onChange={(e) => updatePromptTemplate(promptTemplate.id, e.target.value)}
                        className="admin-input-textarea"
                    />
                </div>
            ))}
        </ContentRenderer>
    );
}


// ========================== GENERATED PROMPTS ===========================
function PromptsGeneratorContent({ subsection }: { subsection: string }) {
    const { getPromptTemplatesForCategory, updatePromptTemplate } = usePromptUpdate();

    return (
        <ContentRenderer
            title={`Generated Prompts - ${fromCamelCaseToCapitalizedSpace(subsection)}`}
            description="Generated prompts are automatically generated by the AI."
        >
            {getPromptTemplatesForCategory(subsection, PromptTemplateType.GENERATED).map((promptTemplate) => (
                <div key={promptTemplate.id} className="admin-input-container">
                    <label className="admin-input-label">
                        {promptTemplate.name}

                        {/* Icon Button with Tooltip */}
                        <div className="tooltip-container">
                            <button className="info-icon-button" aria-label="View response details">
                                ℹ️
                            </button>
                            <div className="tooltip">
                                {promptTemplate.response}
                            </div>
                        </div>

                        {promptTemplate.description && (
                            <span className="admin-input-description">{promptTemplate.description}</span>
                        )}
                    </label>
                    <textarea
                        value={promptTemplate.template}
                        onChange={(e) => updatePromptTemplate(promptTemplate.id, e.target.value, PromptTemplateType.GENERATED)}
                        className="admin-input-textarea"
                    />
                </div>
            ))}
        </ContentRenderer>
    );
}




// ============================== CURRICULUM ==============================
interface CurriculumData { // TODO: DELETE ? Don't think need right now
    description: string;
    objectives: string[];
    prerequisites: string[];
    // ... ??
}

function CurriculumContent({ subsection }: { subsection: string }) {
    const { curriculum } = useCurriculum();
    const { updateCurriculumField, updateArrayField, addArrayItem, removeArrayItem } = useCurriculumUpdate();


    const renderSubsectionContent = () => {
        switch (subsection) {
            case "description":
                return (
                    <div className="admin-input-container">
                        <textarea
                            value={curriculum.description}
                            onChange={(e) => updateCurriculumField("description", e.target.value)}
                            className="admin-input-textarea"
                        />
                    </div>
                );

            case "objectives":
                return (
                    <div className="admin-list-container">
                        {curriculum.objectives.map((objective, index) => (
                            <div key={index} className="admin-list-item">
                                <input
                                    value={objective}
                                    onChange={(e) => updateArrayField("objectives", index, e.target.value)}
                                    className="admin-input-text"
                                />
                                <button
                                    onClick={() => removeArrayItem("objectives", index)}
                                    className="admin-button-remove"
                                >
                                    ✕
                                </button>
                            </div>
                        ))}
                        <button
                            onClick={() => addArrayItem("objectives", "")}
                            className="admin-button-add"
                        >
                            + Add Objective
                        </button>
                    </div>
                );
            
            // TODO: THE REST HERE ...

            default:
                return null;
        }
    };

    return (
        <ContentRenderer
            title={`Curriculum - ${subsection.charAt(0).toUpperCase() + subsection.slice(1)}`}
            description="Define the learning objectives and structure of your course."
        >
            {renderSubsectionContent()}
        </ContentRenderer>
    );
}

// Main Content Router Component
interface SectionContentProps {
    section: string;
    subsection: string;
}
function AdminPanelContent({ section, subsection }: SectionContentProps) {
    const renderContent = () => {
        switch (section) {
            case "prompts":
                return <PromptsContent subsection={subsection} />;
            case "promptsGenerator":
                return <PromptsGeneratorContent subsection={subsection} />;
            case "curriculum":
                return <CurriculumContent subsection={subsection} />;
            default:
                return <div>Section not found</div>;
        }
    };

    return renderContent();
}








export {
    AdminToggle,
    AdminOverlay,
}

