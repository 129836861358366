
import "../css/styles.css";

import { useEffect, useRef, useState } from "react";

import Message from "./Message";

import { PromptTemplateType, usePrompt, usePromptUpdate } from "../context/PromptContext";
import { useMessage, useMessageUpdate } from "../context/MessageContext";
import { useSpeechSynthesisUpdate } from "../context/SpeechSynthesisContext";
//import { useInstruction, useInstructionUpdate } from "../context/ContentDeliveryContext";

import { useSessionUpdate } from "../context/SessionContext";

import { useAIBots, useAIBotsUpdate } from "../context/AIBotsContext";
import { useCurriculum } from "../context/CurriculumContext";


import { INSTRUCTOR_NAME, SERVER_ORIGIN, USE_GENERATED_RESPONSES } from "../common/constants";

import { fetchLecturePage } from "../services/fetch";




const USE_AI_LECTURE = true;



export default function LectureContainer( ) {

  const [ isAtLectureStart, setIsLectureStart ] = useState<boolean>( true );
  const [ isPaused, setIsPaused ] = useState<boolean>( true ); // TODO: Reference useSession's State instead? -- May want to know it's paused across entire App, not just this Component (same for PresentationContainer.tsx)

  const [ pageNumber, setPageNumber ] = useState<number>( 1 );

  const { updateSessionPaused } = useSessionUpdate();
  const { getDefaultAppStateHandlerConfig } = useSessionUpdate();

  const { getCompiledPrompt, handleCompileGenerateAndRevisePrompts } = usePromptUpdate();

  const { lectureMessages } = useMessage();
  const { streamAssistantResponse, processLecturePage } = useMessageUpdate();

  const { pauseSpeechSynthesis, resumeSpeechSynthesis } = useSpeechSynthesisUpdate();

  const { curriculum } = useCurriculum(); // TODO:
  const { generateInstructorLecture, initializeAIBots } = useAIBotsUpdate();



  const messagesEndRef = useRef<HTMLDivElement>( null );


  useEffect( () => {
    if( messagesEndRef.current ) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [lectureMessages]);



  const activateLecture = () => {
    setIsPaused( false );
    setIsLectureStart( false );
    updateSessionPaused( false );
  }


  // TODO: startLecture,handleContinueLecture,togglePause are DUPLICATED(ish) here & in PresentationContainer
  // ... CONSIDER generalizing to a "ContentDeliveryContext" and organizing better
  const startLecture = async () => {
    //const pageContent = await fetchLecturePage( pageNumber, getDefaultAppStateHandlerConfig() );
    //setPageNumber( previous => previous + 1 );
    //await processLecturePage( pageContent );

    /*
    const prompt = "Resume the lecture in whatever way is appropriate.";

    const assistantResponseOptions = { context: "", prompt: prompt, promptType: "generalPrompt", messageType: "LECTURE" };
    await streamAssistantResponse( assistantResponseOptions );
    */

    if( USE_AI_LECTURE ) {
      let startLecturePrompt: string = "";

      if( USE_GENERATED_RESPONSES ) {
        await handleCompileGenerateAndRevisePrompts( "draftInitialLecture", { curriculum: curriculum.description }, 
                                                     "reviseInitialLecture", { curriculum: curriculum.description } );
      }
      else {
        startLecturePrompt = getCompiledPrompt( "lecturePromptInitial", {} );
        /*
        await generateInstructorLecture(`
          Begin the lecture by introducing yourself and explaining
          what we'll cover in today's session on computer programming basics.
        `);
        */
      }

      await generateInstructorLecture( startLecturePrompt );
    } else {
      await handleFetchLecturePage();
    }

    activateLecture();
  };

  const handleContinueLecture = async () => {
    if( USE_AI_LECTURE ) {
      let continueLecturePrompt: string = "";

      if( USE_GENERATED_RESPONSES ) {
        await handleCompileGenerateAndRevisePrompts( "draftContinueLecture", { curriculum: curriculum.description }, 
                                                     "reviseContinueLecture", { curriculum: curriculum.description } );
      }
      else {
        continueLecturePrompt = getCompiledPrompt( "lecturePromptContinue", {} );
        //await generateInstructorLecture( "Continue the lecture from the current point." );
      }

      await generateInstructorLecture( continueLecturePrompt );
    } else {
      await handleFetchLecturePage();
    }

    /*
    const prompt = "Continue the lecture.";

    const assistantResponseOptions = { context: "", prompt: prompt, promptType: "generalPrompt", messageType: "LECTURE" };
    await streamAssistantResponse( assistantResponseOptions );
    */
  };

  const handleFetchLecturePage = async () => {
    const pageContent = await fetchLecturePage( pageNumber, getDefaultAppStateHandlerConfig() );
    await processLecturePage( pageContent );
    setPageNumber( previous => previous + 1 );
  };



  const togglePause = () => {

    if( isPaused ) { // Intending to "Play"
      resumeSpeechSynthesis();
    }
    else { // Intending to "Pause"
      pauseSpeechSynthesis();
    }

    setIsPaused( !isPaused );
  }


  return (
    <div className="lecture-container">
        <div className="lecture-header" >
            Class Lecture
        </div>

        <div id="lecture-messages" className="lecture-messages">
            {lectureMessages.map( (message: IMessage) => {
              return <LectureMessage message={message} key={message.id} />
            })}
            <div ref={messagesEndRef} />
        </div>

        {/*
        <NavigationComponent />
        */}
        <div className="navigation-container">
          <button id="presentation-control-button" className="navigation-button presentation-control-button" type="button" onClick={ () => { initializeAIBots(); } }>Initialize</button>

          <button id="presentation-control-button" className="navigation-button presentation-control-button" type="button" onClick={ () => isAtLectureStart ? startLecture() : togglePause() }>{isPaused ? (isAtLectureStart ? "Start" : "Play") : "Pause"}</button>
          <button className="navigation-button" type="button" onClick={ () => handleContinueLecture() }>Next</button>
        </div>
    </div>
  );
}

/* TODO: Considering a different design where there's a "ContentDeliveryContext" that encompasses both Text-Based Lecture and Presentation-Based Lecture
function NavigationComponent() {

  const { isAtContentStart, startContent, togglePause } = useInstruction();
  const { continueContent } = useInstructionUpdate();

  return (
    <div className="navigation-container">
      <button id="presentation-control-button" className="navigation-button presentation-control-button" type="button" onClick={ () => isAtContentStart ? startContent() : togglePause() }>{isPaused ? (isAtContentStart ? "Start" : "Play") : "Pause"}</button>
      <button className="navigation-button" type="button" onClick={ () => continueContent() }>Next</button>
    </div>
  );
}
*/


interface LectureMessageProps {
  message: IMessage,
}
function LectureMessage( { message }: LectureMessageProps ) {

  return (
    <Message message={message} />
  );
}




