


import { useState, useContext, createContext, useCallback, useMemo } from "react";





interface CurriculumData {
    description: string;
    objectives: string[];
    prerequisites: string[];
    keywords: string[];
    subject: string;
    topic: string;
    goalStatement: string;
    learnerLevel: string;
    learnerAge: string;
    sessionLength: number;
    numberOfSessions: number;
    assessmentType: string;
    learningPreference: string;
    specialRequests: string;
}




interface CurriculumContextType {
    curriculum: CurriculumData;
    compiledCirriculum: string;
}

interface CurriculumUpdateContextType {
    updateCurriculumField: (field: keyof CurriculumData, value: any) => void;
    updateArrayField: (field: keyof CurriculumData, index: number, value: string | null) => void;
    addArrayItem: (field: keyof CurriculumData, value: string) => void;
    removeArrayItem: (field: keyof CurriculumData, index: number) => void;
}

const CurriculumContext = createContext<CurriculumContextType | null>(null);
const CurriculumUpdateContext = createContext<CurriculumUpdateContextType | null>(null);

function useCurriculum() {
    const context = useContext(CurriculumContext);
    if (!context) throw new Error("useCurriculum must be used within CurriculumContextProvider");
    return context;
}

function useCurriculumUpdate() {
    const context = useContext(CurriculumUpdateContext);
    if (!context) throw new Error("useCurriculumUpdate must be used within CurriculumContextProvider");
    return context;
}



interface ComponentProps {
    children: React.ReactNode;
}
function CurriculumContextProvider({ children }: ComponentProps) {
    const [curriculum, setCurriculum] = useState<CurriculumData>({
        description: `Subject: Computer Programming
            Topic: Introduction to Computer Programming
            Keywords: Programming, Variables, Data Types, Control Structures
            Goal: Introduce Basic Programming Concepts and Problem-Solving Skills
            Objectives:
            - Understand what programming is
            - Learn about variables and data types
            - Grasp basic control structures
            Focus: Foundational Concepts in Programming Logic
        `,
        objectives: [
            "Understand what programming is",
            "Learn about variables and data types",
            "Grasp basic control structures",
            "Develop problem-solving skills"
        ],
        prerequisites: [
            "Basic computer literacy",
            "Familiarity with basic math concepts"
        ],
        keywords: [
            "Programming",
            "Variables",
            "Data Types",
            "Control Structures"
        ],
        subject: "Computer Programming",
        topic: "Introduction to Computer Programming",
        goalStatement: "Introduce Basic Programming Concepts and Problem-Solving Skills",
        learnerLevel: "Beginner",
        learnerAge: "12+",
        sessionLength: 30,
        numberOfSessions: 1,
        assessmentType: "Knowledge Check Quiz",
        learningPreference: "Balance of Theory and Hands-On Applications",
        specialRequests: "Ensure content is engaging and interactive, with real-world examples"
    });


    const compileFullDescription = useCallback(() => {
        // Pulls all Curriculum Data together and creates full Curriculum Description
        let description = `Subject: ${curriculum.subject}\n`;
        description += `Topic: ${curriculum.topic}\n`;
        description += `Keywords: ${curriculum.keywords.join(", ")}\n`;
        description += `Goal: ${curriculum.goalStatement}\n`;
        description += "Objectives:\n";
        curriculum.objectives.forEach(obj => {
            description += `- ${obj}\n`;
        });
        description += `Focus: ${curriculum.topic}\n`;
        description += `Learner Level: ${curriculum.learnerLevel}\n`;
        description += `Learner Age: ${curriculum.learnerAge}\n`;
        description += `Lesson Length: ${curriculum.sessionLength} Minutes\n`;
        description += `Number of Sessions: ${curriculum.numberOfSessions}\n`;
        description += `Quick Assessment: ${curriculum.assessmentType}\n`;
        description += `Learning Preference: ${curriculum.learningPreference}\n`;
        description += `Special Requests: ${curriculum.specialRequests}`;

        return description;
    }, [curriculum]);
    const compiledCirriculum = useMemo(() => {
        return compileFullDescription();
    }, [curriculum]);






    const updateCurriculumField = useCallback((field: keyof CurriculumData, value: any) => {
        setCurriculum(previous => ({
            ...previous,
            [field]: value
        }));
    }, []);

    const updateArrayField = useCallback((field: keyof CurriculumData, index: number, value: string | null) => {
        setCurriculum(previous => {
            const array = [...(previous[field] as string[])];

            if (value === null) {
                // Remove item
                array.splice(index, 1);
            } else {
                // Update item
                array[index] = value;
            }

            return {
                ...previous,
                [field]: array
            };
        });
    }, []);

    const addArrayItem = useCallback((field: keyof CurriculumData, value: string) => {
        setCurriculum(previous => ({
            ...previous,
            [field]: [...(previous[field] as string[]), value]
        }));
    }, []);

    const removeArrayItem = useCallback((field: keyof CurriculumData, index: number) => {
        setCurriculum(previous => {
            const array = [...(previous[field] as string[])];
            array.splice(index, 1);

            return {
                ...previous,
                [field]: array
            };
        });
    }, []);









    const contextValue: CurriculumContextType = {
        curriculum,
        compiledCirriculum
    };

    const contextUpdateValue: CurriculumUpdateContextType = {
        updateCurriculumField,
        updateArrayField,
        addArrayItem,
        removeArrayItem
    };

    return (
        <CurriculumContext.Provider value={contextValue}>
            <CurriculumUpdateContext.Provider value={contextUpdateValue}>
                {children}
            </CurriculumUpdateContext.Provider>
        </CurriculumContext.Provider>
    );
}



export {
    CurriculumContextProvider,
    useCurriculum,
    useCurriculumUpdate,
    type CurriculumData
};


