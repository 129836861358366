
import "../css/styles.css";


import { useState, useEffect, useContext, createContext, useCallback, useRef } from "react";


import { DEBUG, DEBUG_VOICE_SPEED, DEFAULT_NOTIFICATION_LIFESPAN, FAKE_STUDENT_NAME, FAKE_STUDENT_VOICES, IDLE_INSTRUCTOR_VIDEO, INSTRUCTOR_NAME, INSTRUCTOR_VOICES, MAX_WAIT_BEFORE_TRY_SPEAK, MIN_SILENCE_BEFORE_NEXT_SPEAK, QUESTIONS_PAUSED_NOTIFICATION, SERVER_ORIGIN, USE_BROWSER_SPEECH_SYNTHESIS_API, VERBOSE, VOICE_SPEED } from "../common/constants";


import { useSession, useSessionUpdate } from "./SessionContext";
import { useNotificationUpdate } from "./NotificationContext";

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import ReportsService from "../services/reports";

import TimeService from "../services/time";
import { fetchOpenAITextToSpeech } from "../services/fetch";

import { SpeakerType } from "../types/enums";


export {
  SpeechSynthesisContextProvider,
  useSpeechSynthesis, useSpeechSynthesisUpdate
};


const SpeechSynthesisContext = createContext<any>( null ); // TODO: Properly fill out

const SpeechSynthesisUpdateContext = createContext<any>( null ); // TODO: Properly fill out


function useSpeechSynthesis() {
  return useContext( SpeechSynthesisContext );
}

function useSpeechSynthesisUpdate() {
  return useContext( SpeechSynthesisUpdateContext );
}






function SpeechSynthesisContextProvider( {children}: ComponentProps ) {


  // TODO: FINISH implementing Avatar Video (see ChatContainer for HTML and where avatarVideoFileName is used)
  const [ avatarVideoFileName, setAvatarVideoFileName ] = useState<string>();

  const speaking = useRef( false );

  const { questionsAllowed, someoneSpeaking } = useSession();
  const { setSomeoneSpeaking, setSlideReadyToMoveOn, muteStudents, unmuteStudents } = useSessionUpdate();

  const { postNotification } = useNotificationUpdate();


  const speechSynthesis = useRef<any>();


  const {
    listening,

    transcript,
    resetTranscript: resetAudioTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();



  const startAudioEvent = USE_BROWSER_SPEECH_SYNTHESIS_API ? "start" : "play";
  const endAudioEvent = USE_BROWSER_SPEECH_SYNTHESIS_API ? "end" : "ended";





  useEffect( () => {
    window.speechSynthesis.resume();
    cancelSpeechSynthesis();
  }, []);



  // ========================== AUDIO / VOICE HANDLING ==========================
  window.speechSynthesis.onvoiceschanged = () => getAvailableDesiredVoices;

  const getAvailableDesiredVoices = useCallback( (otherVoices = [...FAKE_STUDENT_VOICES, ...INSTRUCTOR_VOICES]) => {
    const voices = window.speechSynthesis.getVoices();

    const fineButNotGreatVoices = ["Zira", "Mark"]; // EDGE (at least)
    //"Google UK English Female", "Google UK English Male", "Google US English"]; // CHROME // Specifically listed here since shown available in Chrome as well. Listed separately since they're not great voices, but better than default
    let desiredVoiceNames = ["Yan", "Jenny", "Aria", "Roger", "Steffan", ...fineButNotGreatVoices, ...otherVoices];

    let availableDesiredVoices = voices.filter(voice =>
      desiredVoiceNames.some(desiredVoice => voice.name.includes(desiredVoice))
    );

    if (DEBUG && VERBOSE) { console.groupCollapsed("Available Desired Voices"); console.log(availableDesiredVoices); console.groupEnd(); }

    if (availableDesiredVoices == null) console.warn("No desired Voice available");

    return availableDesiredVoices;
  }, []);


  const doBrowserSpeechSynthesis = useCallback( ( text: string, speaker: string, idealVoiceNames: string[], unmuteAfter: boolean ) => {

    //window.speechSynthesis.cancel();

    // TODO: Get more robust for specific Browsers
    // Voices that sound good (Edge Browser):
    // -- Female: Yan, Jenny, Aria
    // -- Male: Roger, Steffan

    if( DEBUG && VERBOSE ) console.log("IDEAL VOICE(S): ", idealVoiceNames);

    const availableDesiredVoices = getAvailableDesiredVoices();

    let chosenVoice: any = availableDesiredVoices.find( voice =>
      idealVoiceNames.some(idealVoice => voice.name.includes(idealVoice))
    );
    if( chosenVoice == null ) { // If we didn't find the Ideal Voice in Available Desired Voices...
      console.warn(`WARNING: ${idealVoiceNames} (ideal) Voice(s) not available`);
      chosenVoice = availableDesiredVoices ? availableDesiredVoices[0] : null
    }

    const utterance = new SpeechSynthesisUtterance(text);


    if( chosenVoice ) utterance.voice = chosenVoice;

    if( DEBUG ) utterance.rate = DEBUG_VOICE_SPEED;
    else utterance.rate = VOICE_SPEED;

    //setSomeoneSpeaking( true );
    setSlideReadyToMoveOn( false );
    muteStudents();

    utterance.addEventListener( endAudioEvent, () => {
      //setSomeoneSpeaking( false );
      if( unmuteAfter && questionsAllowed ) unmuteStudents();
    });

    return utterance; // Allows Caller to add additional Event Listeners (NOTE: Can add multiple "end" Events, or whatever, and will each be executed)
  }, []);
  const doOpenAITextToSpeech = useCallback( async ( text: string, speaker: string, unmuteAfter: boolean ) => {

    const textToConvert = text;

    let voiceName = "nova"; // TODO: Sync with server's names... use an API call?
    switch( speaker ) {
      case INSTRUCTOR_NAME: {
        voiceName = "nova";
        break;
      }

      case FAKE_STUDENT_NAME: {
        voiceName = "fable";
        break;
      }

      default: {
        console.error( `Unrecognized Speaker: ${speaker}` );
        break;
      }
    }


    try {
      const audioBlob = await fetchOpenAITextToSpeech( textToConvert, voiceName );

      if( !audioBlob ) {
        console.error("ERROR: Fetching OpenAI Text To Speech Failed");
        return null;
      }

      const audioUrl = URL.createObjectURL( audioBlob );
      let audio: any = new Audio( audioUrl );

      audio.addEventListener( endAudioEvent, () => {
        URL.revokeObjectURL( audioUrl );
      });

      const handleError = (e: any) => console.error( "ERROR DURING PLAYBACK:", e );
      audio.onerror = handleError;
      //audio.play();



      if( !audio ) {
        // TODO: Error Handle
        console.error("ERROR: Creating Audio Object Failed");
      }


      // TODO: See if better way, hacking this together to prevent repeated audio when Pause/UnPause after playback finishes (i.e. it caused audio to repeat again)
      // ... Doing something similar in postMessageAndSpeak in MessageContext
      const cleanupAudio = () => {
        //audio.removeEventListener( "error", handleError );
        //audio.onerror = null;

        cancelSpeechSynthesis();

        URL.revokeObjectURL( audioUrl );

        /*
        audio.removeEventListener( endAudioEvent, cleanupAudio );
        audio.src = ""; // Clear the source
        audio.load(); // Stop the audio from being used
        audio = null; // Dereference the audio object
        */
      };

      const startAudio = () => {
        setSomeoneSpeaking( true );
      }

      const endAudio = () => {
        setSomeoneSpeaking( false );

        resetAudioTranscript(); // Safety measure to help ensure studen't mic not pick up bot's audio (which may happen )
        if( unmuteAfter && questionsAllowed ) unmuteStudents();

        audio.removeEventListener( endAudioEvent, endAudio );
        cleanupAudio();
      }

      audio.addEventListener( startAudioEvent, startAudio );
      audio.addEventListener( endAudioEvent, endAudio );

      if( DEBUG && VERBOSE ) console.log("Speak Function Executing -- Passed Checks");

      //setSlideReadyToMoveOn( false ); // If someone spoke recently, then we're not ready to move on
      muteStudents();


      // ========= TODO TESTING TEMP =========
      audio.playbackRate = DEBUG ? DEBUG_VOICE_SPEED : 1.0; // TODO: Testing DEBUG
      //audio.play();

      speechSynthesis.current = audio;
      // =====================================


      return audio;

    } catch( error ) {
      console.error("Error Playing Audio:", error);
      return null;
    }
  }, []);
  const doThirdPartySpeechSynthesis = useCallback( async ( text: string, speaker: string, unmuteAfter: boolean ) => {
    return doOpenAITextToSpeech( text, speaker, unmuteAfter );
  },[])
 
  const speak = useCallback( async ( text: string, { idealVoiceNames = INSTRUCTOR_VOICES, speaker = INSTRUCTOR_NAME, unmuteAfter = true, avatarVideo = IDLE_INSTRUCTOR_VIDEO } = {} ) => {

    resetAudioTranscript(); // Safety measure to help ensure studen't mic not pick up bot's audio (which may happen )

    //playIdleVideo(avatarVideo);

    if( USE_BROWSER_SPEECH_SYNTHESIS_API ) {
      //const utterance = doBrowserSpeechSynthesis(text, messageElementId, idealVoiceNames, unmuteAfter, avatarVideo);
      const utterance = doBrowserSpeechSynthesis(text, speaker, idealVoiceNames, unmuteAfter);
      return utterance;
    }
    else {
      const audio = await doThirdPartySpeechSynthesis( text, speaker, unmuteAfter );
      return audio;
    }

  }, []);




  //              ===== Speech Synthesis APIs =====
  // Separate Functions (even for simple Operations) in case implementation varies between Browsers or APIs we may later use (thus needing logic to properly handle -- this way caller not need change)

  // TODO: Decide if Pause/Resume should change "someoneSpeaking" (may have unintended side-effects like enablng/disabling "someoneSpeaking" when we don't intend to)
  const pauseSpeechSynthesis = useCallback( (  ) => { // All Utterances in Queue not Spoken till UnPaused

    if (USE_BROWSER_SPEECH_SYNTHESIS_API) {
      window.speechSynthesis.pause();
    }
    else {
      if( speechSynthesis?.current ) speechSynthesis.current.pause();
    }

    //sessionDetails.someoneSpeaking = false; // TODO - EXPERIMENTAL
    //if( questionsAllowed ) unmuteStudents();
  }, []);

  const resumeSpeechSynthesis = useCallback( (  ) => { // Continues playing Utterances in Queue
    // TODO: KNOWN BUG - this is called every time user "play" after "pause" app, even if there's nothing more to speak, 
    // ... so students will always be muted.
    muteStudents();

    if( USE_BROWSER_SPEECH_SYNTHESIS_API ) {
      window.speechSynthesis.resume();
    }
    else {
      // TODO: Is this the same pattern for BROWSER_SPEECH_SYNTHESIS ??
      /* DOESN'T WORK, ALWAYS IS TRUE
      if( !speechSynthesis.current.speaking && !speechSynthesis.current.pending ) {
        // Nothing left to be spoken, just return to avoid triggering "play" Event Listener
        return;
      }
      */

      if( speechSynthesis?.current ) speechSynthesis.current.play();
    }
  }, []);

  const cancelSpeechSynthesis = useCallback( () => { // Removes all Utterances from Utterances Queue

    if( USE_BROWSER_SPEECH_SYNTHESIS_API ) {
      window.speechSynthesis.cancel();
    }
    else {
      if( speechSynthesis?.current ) { // Effectively removes Audio (could speechSynthesis.src = "", but that's more if want to reuse, I want essentially remove completely so can set later)
        speechSynthesis.current.pause(); // Pause Audio
        speechSynthesis.current.currentTime = 0; // Reset Current Time (??)
        speechSynthesis.current = null; // Remove reference
      }
    }

    setSomeoneSpeaking( false );
  },[]); 

  // =============================================================================






  return (
    <SpeechSynthesisContext.Provider value={ {speak, avatarVideoFileName} } >
      <SpeechSynthesisUpdateContext.Provider value={ {pauseSpeechSynthesis, resumeSpeechSynthesis, cancelSpeechSynthesis} } >
        {children}
      </SpeechSynthesisUpdateContext.Provider>
    </SpeechSynthesisContext.Provider>
  );


}










/* */