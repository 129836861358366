
import "../css/styles.css";


import { useSession } from "../context/SessionContext";



export default function Header() {

  const { presentationInfo } = useSession();
  if( !presentationInfo ) return null;

  //const subject = presentationInfo?.subject?.[0].toUpperCase() + presentationInfo?.subject?.slice( 1 );
  const subject = presentationInfo.subject.toUpperCase();
  const lessonId = presentationInfo.lessonId;

  return (
    <div className="container-header">
        {/*<h1 className="logo">{`AI<4>Youth`}</h1>*/}
        <h1 className="logo">{`${subject} - Lesson ${lessonId}`}</h1>
    </div>
  );
}


