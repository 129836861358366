
import { useState, useContext, createContext, useCallback, useRef, useEffect } from "react";

import { AppMode, MessageType, PromptType } from "../types/enums";

import { useSession } from "./SessionContext";
import { PromptTemplateType, usePromptUpdate } from "./PromptContext";
import { useCurriculum, useCurriculumUpdate } from "./CurriculumContext";
import { useProfileUpdate } from "./ProfileContext";
import { useMessage, useMessageUpdate } from "./MessageContext";

import { INSTRUCTOR_NAME, FAKE_STUDENT_NAME, USE_GENERATED_RESPONSES } from "../common/constants";





interface ChatMessage { // TODO: Use IMessage from type file instead?
    text: string;
    sender: string;
    type: MessageType;
}

interface ChatContext {
    //curriculumDescription: string;
    previousMessages: Array<{
        text: string;
        sender: string;
        timestamp: number;
        type: MessageType;
    }>;
}



interface AIBotsContextType {
    isProcessing: boolean;
    currentRound: number;
    setCurrentRound: (round: number) => void;
}

interface AIBotsUpdateContextType {
    generateInstructorLecture: (prompt: string) => Promise<void>;
    generateStudentResponse: (instructorMessage: string) => Promise<void>;
    generateInstructorResponse: (studentMessage: string) => Promise<void>;
    generateInstructorResponseToHuman: (studentMessage: string) => Promise<void>;
    startNextRound: () => void;
    clearCurrentSession: () => void;
    setMaxRounds: (rounds: number) => void;

    initializeAIBots: () => Promise<void>;
}



const AIBotsContext = createContext<AIBotsContextType | null>(null);
const AIBotsUpdateContext = createContext<AIBotsUpdateContextType | null>(null);




function useAIBots() {
    const context = useContext(AIBotsContext);
    if (!context) throw new Error("useAIBots must be used within AIBotsContextProvider");
    return context;
}

function useAIBotsUpdate() {
    const context = useContext(AIBotsUpdateContext);
    if (!context) throw new Error("useAIBotsUpdate must be used within AIBotsContextProvider");
    return context;
}




const PROBABILITY_STUDENT_RESPONDS = 1;

const AUTOMATIC_START_NEXT_ROUND = false;



interface AIBotsProviderProps {
  children: React.ReactNode;
  maxRounds?: number;
}
function AIBotsContextProvider({
    children,
    maxRounds = 5,
}: AIBotsProviderProps) {

    const [isProcessing, setIsProcessing] = useState( false );
    const [currentRound, setCurrentRound] = useState( 0 );
    const maxRoundsRef = useRef( maxRounds );
    const lectureProgressRef = useRef<string>( "" );
    const conversationHistoryRef = useRef<ChatMessage[]>( [] );

    const { curriculum } = useCurriculum();
    const { updateCurriculumField } = useCurriculumUpdate();

    const { getCompiledPrompt, handleQuestion, handleGeneralPrompt, handleGeneratePrompt, handleReviseGeneratedPrompt, handleGenerateAndRevisePrompt, handleCompileGenerateAndRevisePrompts, handleCompileGenerateAndPublishPrompts, updatePromptTemplateResponse } = usePromptUpdate();

    const { postMessageAndSpeakInChunks } = useMessageUpdate();
    const { getHumanStudentName } = useProfileUpdate();

    const { appMode } = useSession();

    const [ curriculumDescription, setCurriculumDescription ] = useState<string>( "" ); // TODO: Something better to initialize it with?
    const [ teachingGuidelines, setTeachingGuidelines ] = useState<string>( "" ); // TODO: Something better to initialize it with?
    const [ realStudentEngagementGuidelines, setRealStudentEngagementGuidelines ] = useState<string>( "" ); // TODO: Something better to initialize it with?



    useEffect( () => {
        if( !curriculumDescription ) return;

        const updateTeachingGuidelines = async () => {
            const newTeachingGuidelines = await getTeachingGuidelines();
            setTeachingGuidelines( newTeachingGuidelines );
        };

        updateTeachingGuidelines();
    }, [curriculumDescription]);

    useEffect( () => {
        if( !curriculumDescription ) return;

        const updateRealStudentEngagementGuidelines = async () => {
            const newRealStudentEngagementGuidelines = await getRealStudentEngagementGuidelines();
            setRealStudentEngagementGuidelines( newRealStudentEngagementGuidelines );
        };

        updateRealStudentEngagementGuidelines();
    }, [curriculumDescription]);




    const initializeAIBots = async () => {
        const newCurriculumDescription = await getCurriculumDescription();
        setCurriculumDescription( newCurriculumDescription );
        updateCurriculumField( "description", newCurriculumDescription );
    };





    const updateConversationHistory = useCallback((message: ChatMessage) => {
        conversationHistoryRef.current = [...conversationHistoryRef.current, message];
    }, []);


    const getUpdatedContext = useCallback(() => {
        return `
            Curriculum Description: ${curriculumDescription}
            Current Conversation History: ${conversationHistoryRef.current
                                            .slice(-3)
                                            .map(msg => `${msg.sender}: ${msg.text}`)
                                            .join("\n")}
            Last Lecture Point: ${lectureProgressRef.current}
        `;
    }, [curriculumDescription, conversationHistoryRef, lectureProgressRef]);



    const generateInstructorLecture = useCallback(async (prompt: string): Promise<void> => {
        try {
            const context = `
                ${getUpdatedContext()}

                Follow these teaching guidelines: ${teachingGuidelines}
            `;

            prompt = `${context}\n${prompt}`; // TODO: better way add context in? (do like for transcript / presentation context where the handleGeneralPrompt alredy passes, but need add to SessionContext or something)
            const lectureResponse = await handleGeneralPrompt(prompt);


            console.log("(generateInstructorLecture) GENERATE INTRUCTOR LECTURE PROMPT:", prompt);

            if (lectureResponse) {
                lectureProgressRef.current = lectureResponse;
                const studentShouldRespond = Math.random() > 1 - PROBABILITY_STUDENT_RESPONDS;

                await postMessageAndSpeakInChunks(
                    {
                        message: lectureResponse,
                        sender: INSTRUCTOR_NAME,
                        type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                    },
                    {},
                    studentShouldRespond ? () => generateStudentResponse(lectureResponse) : startNextRound
                );

                updateConversationHistory({
                    text: lectureResponse,
                    sender: INSTRUCTOR_NAME,
                    type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                });
            }
        } catch (error) {
            console.error("Error in generateInstructorLecture:", error);
            setIsProcessing(false);
        }
    }, [handleGeneralPrompt, postMessageAndSpeakInChunks, getUpdatedContext]);

    const generateStudentResponse = useCallback(async (messageToRespondTo: string): Promise<void> => {
        try {
            let studentResponsePrompt = "";
            if( USE_GENERATED_RESPONSES ) {
                studentResponsePrompt = await handleCompileGenerateAndRevisePrompts( "draftStudentBotResponse", {}, 
                                                                                     "publishStudentBotResponse", {} );
            } else {
                studentResponsePrompt = getCompiledPrompt( "studentBotPromptResponse" );
            }

            // TODO: The context here should be actual context, not mixed with prompt
            const prompt = `
                ${getUpdatedContext()}
                ${studentResponsePrompt}

                The student is responding to: "${messageToRespondTo}"
            `;

            console.log("(generateStudentResponse) GENERATE STUDENT RESPONSE PROMPT:", prompt);

            const studentResponse = await handleQuestion(prompt, FAKE_STUDENT_NAME);

            if( studentResponse ) {
                updateConversationHistory({
                    text: studentResponse,
                    sender: FAKE_STUDENT_NAME,
                    type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                });

                await postMessageAndSpeakInChunks(
                    {
                        message: studentResponse,
                        sender: FAKE_STUDENT_NAME,
                        type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                    },
                    {},
                    () => generateInstructorResponse(studentResponse)
                );
            }
        } catch( error ) {
            console.error("Error in generateStudentResponse:", error);
            setIsProcessing(false);
        }
    }, [handleQuestion, postMessageAndSpeakInChunks, getUpdatedContext]);

    const generateInstructorResponse = useCallback(async (messageToRespondTo: string): Promise<void> => {
        try {
            let instructorResponsePrompt = "";
            if( USE_GENERATED_RESPONSES ) {
                instructorResponsePrompt = await handleCompileGenerateAndRevisePrompts( "draftInstructorResponseToStudentBot", {}, 
                                                                                        "publishInstructorResponseToStudentBot", {} );
            } else {
                instructorResponsePrompt = getCompiledPrompt( "instructorPromptResponseToStudentBot" );
            }

            // TODO: The context here should be actual context, not mixed with prompt
            // TODO: This whole thing is being taken as the PROMPT/QUESTION but it's not all prompt...
            const prompt = `
                ${getUpdatedContext()}
                ${instructorResponsePrompt}

                The student's message was: "${messageToRespondTo}"
            `;

            console.log("(generateInstructorResponse) GENERATE INSTRUCTOR RESPONSE PROMPT:", prompt);

            //const instructorResponse = await handleQuestion(studentMessage, INSTRUCTOR_NAME);
            const instructorResponse = await handleQuestion(prompt, FAKE_STUDENT_NAME);

            if (instructorResponse) {
                updateConversationHistory({
                    text: instructorResponse,
                    sender: INSTRUCTOR_NAME,
                    type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                });

                await postMessageAndSpeakInChunks(
                    {
                        message: instructorResponse,
                        sender: INSTRUCTOR_NAME,
                        type: appMode == AppMode.TEXT ? MessageType.LECTURE : MessageType.CHAT
                    },
                    {},
                    AUTOMATIC_START_NEXT_ROUND ? startNextRound : () => {}
                );
            }
        } catch (error) {
            console.error("Error in generateInstructorResponse:", error);
            setIsProcessing(false);
        }
    }, [handleQuestion, postMessageAndSpeakInChunks, getUpdatedContext]);


    const generateInstructorResponseToHuman = useCallback(async (messageToRespondTo: string): Promise<void> => {
        try {
            let instructorResponsePrompt = "";
            /*
            if( USE_GENERATED_RESPONSES ) {
                // TODO: Move to single helper function? like generateAndPublishPrompt ??
                const draftPrompt = getCompiledPrompt( "draftInstructorBotEngageRealStudentGuidelines" );
                const publishPrompt = getCompiledPrompt( "publishInstructorBotEngageRealStudentGuidelines" );
                const generatedPrompt = await handleGeneratePrompt( draftPrompt );
                const revisedPrompt = await handleReviseGeneratedPrompt( publishPrompt, generatedPrompt );
                instructorResponsePrompt = revisedPrompt;
            } else {
                instructorResponsePrompt = getCompiledPrompt( "instructorPromptResponseToStudentBot" );
            }
            */

            // TODO: The context here should be actual context, not mixed with prompt
            // TODO: This whole thing is being taken as the PROMPT/QUESTION but it's not all prompt...
            const prompt = `
                ${getUpdatedContext()}
                Follow these teaching guidelines: ${teachingGuidelines}
                Follow these human engagement guidelines: ${realStudentEngagementGuidelines}

                Respond to the student.
                The student's message was: "${messageToRespondTo}"
            `;

            //const instructorResponse = await handleQuestion(studentMessage, INSTRUCTOR_NAME);
            const instructorResponse = await handleQuestion(prompt, getHumanStudentName());

            if (instructorResponse) {
                updateConversationHistory({
                    text: instructorResponse,
                    sender: INSTRUCTOR_NAME,
                    type: MessageType.CHAT
                });

                await postMessageAndSpeakInChunks(
                    {
                        message: instructorResponse,
                        sender: INSTRUCTOR_NAME,
                        type: MessageType.CHAT
                    },
                    {},
                    //AUTOMATIC_START_NEXT_ROUND ? startNextRound : () => {}
                );
            }
        } catch (error) {
            console.error("Error in generateInstructorResponseToHuman:", error);
            setIsProcessing(false);
        }
    }, [handleQuestion, postMessageAndSpeakInChunks, getUpdatedContext]);





    const startNextRound = useCallback(async () => {
        if (currentRound < maxRoundsRef.current) {
            setCurrentRound(prev => prev + 1);

            let continueLecturePrompt = "";
            if( USE_GENERATED_RESPONSES ) {
                continueLecturePrompt = await handleCompileGenerateAndRevisePrompts( "draftContinueLecture", {}, 
                                                                                      "publishContinueLecture", {} );
            } else {
                continueLecturePrompt = getCompiledPrompt( "lecturePromptContinue" );
            }

            // TODO: The context here should be actual context, not mixed with prompt
            const continuationPrompt = `
                ${getUpdatedContext()}
                ${continueLecturePrompt}

                You left off here: "${lectureProgressRef.current}"
                Follow these teaching guidelines: ${teachingGuidelines}
            `;

            generateInstructorLecture( continuationPrompt );
        } else {
            setIsProcessing(false);
            console.log("Lecture completed after reaching maximum rounds");
        }
    }, [currentRound, generateInstructorLecture]);



    const clearCurrentSession = useCallback(() => {
        setCurrentRound(0);
        setIsProcessing(false);
        lectureProgressRef.current = "";
        conversationHistoryRef.current = [];
    }, []);

    const setMaxRounds = useCallback((rounds: number) => {
        maxRoundsRef.current = rounds;
    }, []);




    const getCurriculumDescription = useCallback( async () => {
        let curriculumDescriptionToUse = "";
        if( USE_GENERATED_RESPONSES ) {
            /*
            const basicLectureInfo = `
                Subject: ${curriculum.subject}
                Topic: ${curriculum.topic}
                Goal: ${curriculum.goalStatement}
                Learner Level: ${curriculum.learnerLevel}
                Learner Age: ${curriculum.learnerAge}
                Keywords: ${curriculum.keywords}
            `;
            */
            const basicLectureInfo = curriculum.description;
            curriculumDescriptionToUse = await handleCompileGenerateAndRevisePrompts( "draftCurriculum", { basicLectureInfo }, 
                                                                                      "reviseCurriculum", {} );
        }
        else {
            // TODO: Probably want to do something else for default here (e.g. use what user entered and mock-up something relevant)
            curriculumDescriptionToUse = getDefaultCurriculumDescription();
        }

        return curriculumDescriptionToUse;
    }, [curriculum, getCompiledPrompt, handleReviseGeneratedPrompt]);

    const getTeachingGuidelines = useCallback( async () => {
        let teachingGuidelinesToUse = "";

        if( USE_GENERATED_RESPONSES ) {
            teachingGuidelinesToUse = await handleCompileGenerateAndRevisePrompts( "draftTeachingGuidelines", { curriculum: curriculumDescription }, 
                                                                                   "reviseTeachingGuidelines", { curriculum: curriculumDescription } );
        }
        else {
            // TODO: Probably want to do something else for default here (e.g. use what user entered and mock-up something relevant)
            teachingGuidelinesToUse = "Be a good teacher..."; // TODO: Have from default prompt templates ?
        }

        return teachingGuidelinesToUse;
    }, [curriculumDescription, getCompiledPrompt, handleReviseGeneratedPrompt]);

    const getRealStudentEngagementGuidelines = useCallback( async () => {
        let realStudentEngagementGuidelinesToUse = "";

        if( USE_GENERATED_RESPONSES ) {
            //realStudentEngagementGuidelinesToUse = handleCompileGenerateAndPublishPrompts( "draftInstructorBotEngageRealStudentGuidelines", { curriculum: curriculumDescription }, 
                                                                                           //"reviseInstructorBotEngageRealStudentGuidelines", { curriculum: curriculumDescription } );
            const draftPromptId = "draftInstructorBotEngageRealStudentGuidelines";
            const draftPrompt = getCompiledPrompt( draftPromptId, { curriculum: curriculumDescription }, PromptTemplateType.GENERATED );
            //const publishPrompt = getCompiledPrompt( "publishInstructorBotEngageRealStudentGuidelines", { curriculum: curriculumDescription }, PromptTemplateType.GENERATED );
            const generatedPrompt = await handleGeneratePrompt( draftPrompt );
            //const revisedPrompt = await handleReviseGeneratedPrompt( publishPrompt, generatedPrompt );

            updatePromptTemplateResponse( draftPromptId, generatedPrompt, PromptTemplateType.GENERATED );

            console.log("(getRealStudentEngagementGuidelines) DRAFT PROMPT:", draftPrompt);
            //console.log("(getRealStudentEngagementGuidelines) PUBLISH PROMPT:", publishPrompt);
            console.log("(getRealStudentEngagementGuidelines) GENERATED PROMPT:", generatedPrompt);
            //console.log("(getRealStudentEngagementGuidelines) REVISED PROMPT:", revisedPrompt);

            // TODO: Above used as PUBLISH and not really revise or whatever
            //const revisedPrompt = await handleGenerateAndRevisePrompt( draftPrompt, revisePrompt );
            //realStudentEngagementGuidelinesToUse = revisedPrompt;
            realStudentEngagementGuidelinesToUse = generatedPrompt;
        }
        else {
            realStudentEngagementGuidelinesToUse = "Be a good teacher to the student..."; // TODO: Have from default prompt templates ?
        }

        return realStudentEngagementGuidelinesToUse;
    }, [curriculumDescription, getCompiledPrompt, handleGeneratePrompt, handleReviseGeneratedPrompt]);








    const contextValue: AIBotsContextType = {
        isProcessing,
        currentRound,
        setCurrentRound
    };

    const updateContextValue: AIBotsUpdateContextType = {
        generateInstructorLecture,
        generateStudentResponse,
        generateInstructorResponse,
        generateInstructorResponseToHuman,
        startNextRound,
        clearCurrentSession,
        setMaxRounds,

        initializeAIBots
    };

    return (
        <AIBotsContext.Provider value={contextValue}>
            <AIBotsUpdateContext.Provider value={updateContextValue}>
                {children}
            </AIBotsUpdateContext.Provider>
        </AIBotsContext.Provider>
    );
}





// ============================== HELPERS ==============================

function getDefaultCurriculumDescription() {

    const curriculumDescription = `
        Subject: Computer Programming
        Topic: Introduction to Computer Programming
        Keywords: Programming, Variables, Data Types, Control Structures
        Goal: Introduce Basic Programming Concepts and Problem-Solving Skills
        Objectives:
        - Understand what programming is
        - Learn about variables and data types
        - Grasp basic control structures
        Focus: Foundational Concepts in Programming Logic
        Learner Level: Beginner
        Learner Age: 12+
        Lesson Level: Introductory
        Lesson Length: 30 Minutes
        Number of Sessions: 1
        Session Length: 30 Minutes
        Quick Assessment: Knowledge Check Quiz at the End
        Learning Preference: Balance of Theory and Hands-On Applications
        Special Requests: Ensure content is engaging and interactive, with real-world examples


        Response Length: Keep all responses less than 200 words
    `;

    return curriculumDescription;
}





export {
    AIBotsContextProvider,

    useAIBots, useAIBotsUpdate,
}
