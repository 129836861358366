
import "../css/styles.css";

import { useEffect, useRef, useState } from "react";


import { DEBUG, INSTRUCTOR_NAME, QUESTIONS_PAUSED_NOTIFICATION, VERBOSE, WAIT_UNTIL_NO_ONE_SPEAKING_NOTIFICATION } from "../common/constants";
import { MessageType } from "../types/enums";

import Message from "./Message";

import { useSpeechSynthesis, useSpeechSynthesisUpdate } from "../context/SpeechSynthesisContext";

// NOTE: Only supported in Chrome w/ how I'm using this: https://www.npmjs.com/package/react-speech-recognition#supported-browsers
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
//import { useSpeechRecognitionUpdate } from "../context/SpeechRecognitionContext";

import { useNotificationUpdate } from "../context/NotificationContext";
import { useMessage, useMessageUpdate } from "../context/MessageContext";
import { useSession, useSessionUpdate } from "../context/SessionContext";
import { useProfileUpdate } from "../context/ProfileContext";
import { usePromptUpdate } from "../context/PromptContext";
import { useAIBotsUpdate } from "../context/AIBotsContext";


import ReportsService from "../services/reports";

import AtomSpinner from "../common/AtomSpinner";


const sendIcon = require( "../assets/send_icon.png" );



export default function ChatContainer( ) {

  const [ inputValue, setInputValue ] = useState<string>( "" );
  //const [ micMuted, setMicMuted ] = useState<boolean>( false );

  const [ selectedFile, setSelectedFile ] = useState<any>(); // TODO: TYPE


  const { messages } = useMessage();
  const { postMessage, postMessageAndSpeakInChunks, streamAssistantResponse } = useMessageUpdate();
  const { postNotification } = useNotificationUpdate();

  const { someoneSpeaking, questionsAllowed, isMuted, chatMessagePending } = useSession();
  const { muteStudents, unmuteStudents, getSessionPaused } = useSessionUpdate();

  const { generateInstructorResponseToHuman } = useAIBotsUpdate();

  //const { recognizeSpeech, stopSpeechRecognition } = useSpeechRecognitionUpdate();
  const { avatarVideoFileName } = useSpeechSynthesis();

  const { handleQuestion } = usePromptUpdate();

  const { getHumanStudentName } = useProfileUpdate();



  const {
    listening,

    transcript,
    resetTranscript: resetAudioTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  if( !browserSupportsSpeechRecognition ) {
    // TODO: ERROR HANDLE
  }
  useEffect( () => {
    SpeechRecognition.startListening({ continuous: true });

    // CLEANUP (on UnMount)
    return () => { SpeechRecognition.stopListening() };
  }, [SpeechRecognition.startListening, SpeechRecognition.stopListening]);
  useEffect( () => {

    let timerId: NodeJS.Timeout;

    if( !isMuted && questionsAllowed ) {

      timerId = setTimeout( async () => {

        if( transcript.length > 0 ) {
          postMessage({ messageText: transcript, senderName: getHumanStudentName(), attachedFileName: selectedFile?.name });

          // TODO: This pattern (and setInstructorResponsePending(false)) is used in sendMessage() as well, so maybe consolidate?
          removeFile(); // Ensure file is removed from input

          const response = await handleQuestion( transcript, getHumanStudentName(), selectedFile );
          await postMessageAndSpeakInChunks({ message: response, type: MessageType.CHAT });

          //console.log("AUDIO TRANSCRIPT (USE EFFECT SET TIMEOUT TRANSCRIPT LENGTH > 0)...", transcript);
          resetAudioTranscript();
        }

      }, 1500); // milliseconds of no speech detected
    }

    // CLEANUP (on UnMount)
    return () => clearTimeout( timerId );
  }, [isMuted, questionsAllowed, transcript, resetAudioTranscript]);

  /*
  useEffect( () => {
    // From this console.log, seems this resetAudioTranscript is being used the most
    console.log("IGNORED - AUDIO TRANSCRIPT (USE EFFECT IS MUTED ONLY ...)...", transcript);

    //resetAudioTranscript();
  }, [isMuted] );
  */

  useEffect( () => {
    resetAudioTranscript();

    if( isMuted ) {
      SpeechRecognition.stopListening(); // TODO: Not clear this is doing anything...
      //console.log("STOP LISTENING - AUDIO TRANSCRIPT (USE EFFECT IS MUTED & OTHERS)...", transcript);
    } else {
      SpeechRecognition.startListening({ continuous: true });
      //console.log("START LISTENING - AUDIO TRANSCRIPT (USE EFFECT IS MUTED & OTHERS)...", transcript);
    }
  }, [isMuted, SpeechRecognition.startListening, SpeechRecognition.stopListening, resetAudioTranscript])



  const messagesEndRef = useRef<HTMLDivElement>( null );



  useEffect( () => {
    if( messagesEndRef.current ) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);


  const handleInputChange = ( event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue( event?.target.value );
    adjustHeight( event.target );
  }

  const handleKeyDown = async ( event: React.KeyboardEvent<HTMLTextAreaElement>) => {

    if( event.key == "Enter" && !event.shiftKey ) {

      if( inputValue.trim() ) {
        event.preventDefault();
        await sendMessage( getHumanStudentName() );
      }
    }
    else if( event.key === "Backspace" || event.key === "Delete" ) {
      //textArea.style.height = "auto";
    }
  }

  const updateFile = ( file: File | null ) => { // TODO: TYPE
    if( !file ) return;

    setSelectedFile( file );
  }

  const removeFile = () => { // TODO: TYPE
    setSelectedFile( null );
  }

  const toggleMute = () => {
    if( getSessionPaused() ) {
      postNotification( "You can't unmute while the session is paused." );
      return;
    }

    // TODO: See if can combine recognizeSpeech / stopSpeechRecognition in unmuteStudents / muteStudents (respectively) -> Makes most sense there, but currently the way Contexts are aligned, it can't be done
    // ... TODO: UGLY having to repeat this if unmuteStudents, recognizeSpeech, etc. in multiple places to actually MUTE or UNMUTE... (error-prone cuz need remember to do both!!) -- E.g. PresentationContext
    // TODO: mute/unmutestudents still returns true/false, can that be undone now?
    if( isMuted ) unmuteStudents();
    else {
      muteStudents();
      //console.log("AUDIO TRANSCRIPT (TOGGLE MUTE)...", transcript);
      resetAudioTranscript();
    }
  }

  const sendMessage = async ( senderName: string, messageOverride?: string ) => {

    if( someoneSpeaking ) {
      postNotification( WAIT_UNTIL_NO_ONE_SPEAKING_NOTIFICATION );
      return;
    }
    else if( !questionsAllowed ) { // Prevent Messages from sending if Students unable to post them (that way nothing in Chat goes unanswered or unaddressed) -- TODO: Currently assumes only questions posted (i.e. no commands, general comments, or responses to Bot's questions)
      postNotification( QUESTIONS_PAUSED_NOTIFICATION );
      return;
    }
    else if( isMuted ) {
      postNotification( "You can't send a message while muted." );
      return;
    }
    else if( chatMessagePending ) {
      //postNotification( "Please wait for your last message to finish sending before sending a new one." );
      postNotification( "There's an outstanding message being sent. Please wait for it to finish before sending a new one." ); // TODO: Consider using instructorResponsePending still for use-cases like this where only want to know if student sent a message that is pending, as a debounce (or consider different state or approach altogether to recognize this)
      return;
    }

    let message = messageOverride || inputValue;
    setInputValue( "" );

    //resetTextInput( input );

    if (message.length == 0) return; // Don't say anything


    generateInstructorResponseToHuman( message );

    //postMessage({ messageText: message, senderName, attachedFileName: selectedFile?.name });
    removeFile(); // Ensure file is removed from input


    /*
    const assistantResponseOptions = { context: "", prompt: "", promptType: "questionAnswer", messageType: MessageType.CHAT };
    await streamAssistantResponse( assistantResponseOptions );
    */

    const response = await handleQuestion( message, getHumanStudentName(), selectedFile );
    await postMessageAndSpeakInChunks({ message: response, type: MessageType.CHAT });
  }


  const adjustHeight = ( textArea: HTMLTextAreaElement ) => {
    textArea.style.height = "auto";
    textArea.style.height = `${textArea.scrollHeight}px`;
  }

  const sendKeyword = async( senderName : string, message : string) => {
    sendMessage(senderName, message)
  }

  return (
    <div className="chat-container">
        {/* TODO: This vide-wrapper will likely be moved to the ChatHead Component when we use that again */}
        <div id="video-wrapper">
          <div>
            {/* <video id="talk-video" width="200" height="200" autoPlay style={{position: "fixed"; left: "78%"; top: "4%", borderRadius: "50%", backgroundColor: "red", zIndex: "1"}}></video> */}
            <video id="talk-video" src={avatarVideoFileName} width="170" height="170" loop autoPlay style={{position: "fixed", left: "80%", top: "10px", borderRadius: "50%", zIndex: "1"}}></video>
          </div>
        </div>

        <div className="chat-header" >
            Class Chat
            <i className="fa-solid fa-bug report-bug" onClick={ () => ReportsService.reportBug() }></i>
        </div>

        <div id="chat-messages" className="chat-messages">
          {messages.map( (message: IMessage) => {
            return <ChatMessage message={message} key={message.id} />
          })}

          { chatMessagePending && <AtomSpinner /> }

          <div ref={messagesEndRef} />
        </div>

        <div className="chat-input-container" >
            <button id="microphone" className={`microphone ${isMuted ? "muted" : ""}`} onClick={ () => toggleMute() } type="button">
              <i id="microphone-icon" className={`fas ${isMuted ? "fa-microphone-slash" : "fa-microphone"}`}></i>
            </button>

            {/*
            <FileInput onFileChange={updateFile} onFileRemove={removeFile} attachedFileCount={selectedFile ? 1 : 0} />
            */}

            <textarea id="chat-input_text" className="chat-input_text" value={inputValue} placeholder="Send a message" maxLength={10000} 
                      onChange={handleInputChange} onKeyDown={handleKeyDown} />
            <img src={sendIcon}  className="chat-input_button" onClick={ () => sendMessage( getHumanStudentName() ) } />
        </div>

        <div style={{margin: 5}}></div>

        { selectedFile && (
          <div className="file-attachment-info">
              {selectedFile.name} will be included in your message to the instructor
          </div>
        )}
    </div>
  );
}


interface ChatMessageProps {
  message: IMessage,
}
function ChatMessage( { message }: ChatMessageProps ) {

  return (
    <Message message={message} />
  );
}


interface FileInputProps {
  onFileChange: (file: File | null) => void;
  onFileRemove: () => void;
  iconClass?: string;
  iconStyle?: React.CSSProperties;
  attachedFileCount?: number;
}
function FileInput({ onFileChange, onFileRemove, attachedFileCount = 0, iconClass = "fas fa-paperclip", iconStyle = { fontSize: "24px" } }: FileInputProps) {
  const fileInputRef = useRef<HTMLInputElement>( null );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFileChange( event.target.files ? event.target.files[0] : null );
  };

  const handleFileRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onFileRemove();
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <input type="file" style={{ display: "none" }} onChange={handleFileChange} ref={fileInputRef} />
      <button onClick={triggerFileInput} className="file-upload-icon">
        <i className={iconClass} style={iconStyle}></i>

        {attachedFileCount > 0 && (
          <>
            <span className="count-badge">{attachedFileCount}</span>

            <button onClick={handleFileRemove} className="remove-badge">
              <i className="fas fa-times"></i>
            </button>
          </>
        )}
      </button>
    </>
  );
}


// #################################### HELPERS ####################################

function cancelSpeechSynthesis() {}
function pausePresentation() {}







/*
function resetTextInput(textInput) {
	// TODO: CLEAN UP CODE (still in texting -- Not complete or robust)

	// Reset Text Input Scroll Bar (even if not shown, just in case)
	textArea.style.overflow = "hidden";
	setTimeout(() => { textArea.style.overflow = "auto" }, 100); // After short delay to give time for scrollbar to hide (take effect)

	// Reset Text Input Size
	textArea.style.height = "auto";
	let lineHeight = window.getComputedStyle(textArea).lineHeight;
	const estimatedLineHeight = parseFloat(window.getComputedStyle(textArea).fontSize) * 1.2;
	lineHeight = (lineHeight === "normal") ? estimatedLineHeight : lineHeight;
	textArea.style.height = lineHeight + "px";
}
*/

